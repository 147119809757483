import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SearchPage from './search/SearchPage'
import HomePage from './home/HomePage'
import SearchDetail from './search/SearchDetail'
import AddComponentPage from './add/AddComponentPage'
import ModifyComponentPage from './modify/ModifyComponentPage'
import AdminApprovalPage from './admin/AdminApprovalPage'
import DeleteComponentPage from './delete/DeleteComponentPage'
import { UserRoles } from './reusable/constants/Identifiers'
import RouteWithPermission from './RouteWithPermission'

export const Routing = (): JSX.Element => (
    <>
        <Router>
            <div>
                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route path='/search/:id' component={SearchDetail} />
                    <Route path='/search' component={SearchPage} />
                    <Route path='/add' component={AddComponentPage} />
                    <Route path='/modify' component={ModifyComponentPage} />
                    <Route path='/delete' component={DeleteComponentPage} />
                    <RouteWithPermission
                        exact
                        path='/admin'
                        component={AdminApprovalPage}
                        claimWithRole={[UserRoles.ADMIN]}
                    />
                </Switch>
            </div>
        </Router>
    </>
)
