import React from 'react'
import { FormField, Header, TextContent } from '@amzn/awsui-components-react'

export const FormFieldPrimaryHeader = (props) => {
    return (
        <FormField
            label={
                <TextContent>
                    <Header variant='h3'> {props.header} </Header>
                </TextContent>
            }
            description={props.description}
            stretch={true}
        ></FormField>
    )
}

export default FormFieldPrimaryHeader
