import React from 'react'
import { Icon, Popover, SpaceBetween, TextContent } from '@amzn/awsui-components-react'
import { FormField } from '@amzn/awsui-components-react/polaris'

export const FormFieldLabel = (props) => {
    return (
        <FormField
            label={
                <SpaceBetween direction='horizontal' size='xs'>
                    <TextContent>
                        <strong>{props.mainContent}</strong>
                    </TextContent>
                    <Popover
                        position='top'
                        size='small'
                        triggerType='custom'
                        content={props.popover}
                    >
                        <Icon name='status-info' />
                    </Popover>
                </SpaceBetween>
            }
        ></FormField>
    )
}

export default FormFieldLabel
