import { useState } from 'react'
import SideNavigation from '@amzn/awsui-components-react/polaris/side-navigation'
import { useHistory } from 'react-router-dom'
import { SJ_USER_GUIDE } from '../reusable/constants/Identifiers'

const SideNavBar = () => {
    const history = useHistory()
    const [activeHref, setActiveHref] = useState('/')

    const handleClick = (e) => {
        e.preventDefault()
        if (e.detail['href'] === undefined) {
            history.push('/')
            return
        }
        history.push(e.detail.href)
    }

    return (
        <SideNavigation
            activeHref={activeHref}
            header={{ href: '/', text: 'Scrub Jay' }}
            onFollow={handleClick}
            items={[
                { type: 'link', text: 'Search', href: '/search' },
                { type: 'link', text: 'Add', href: '/add' },
                { type: 'link', text: 'Modify', href: '/modify' },
                { type: 'link', text: 'Delete', href: '/delete' },
                { type: 'divider' },
                {
                    type: 'link',
                    text: 'User Guide',
                    href: SJ_USER_GUIDE,
                    external: true,
                },
            ]}
        />
    )
}

export default SideNavBar
