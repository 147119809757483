import {toolTipsProps} from '../../add/AddComponentPage'
import {APIPaths} from '../constants/ApiPaths'
import {DeleteComponentPayload} from '../../../openapi_server'
import React from 'react'
import {
    AcceptableAltValues,
    AlertTypes,
    LabelsForComponentIDDropdownCategories,
    LoadingState,
    RequestStatus,
    RequestType,
} from '../constants/Identifiers'
import { checkType } from '../functions/verifyAttributes'

export function isNumber(str): boolean {
    if (typeof str !== 'string' || str.trim() === '') {
        return false
    }

    return !Number.isNaN(Number(str))
}

export const getSearchQueryOutput = (
    apiClient,
    searchValue,
    setOutput,
    setFlashBarItems,
    setEmptyResponse,
    setOutputVisible,
) => {
    let queryPath = ''
    if (
        searchValue &&
        searchValue.toLowerCase().includes('-') &&
        searchValue.split('-')[0].length === 3 &&
        isNumber(searchValue.split('-')[0])
    ) {
        queryPath = APIPaths.COMPONENT_DETAILS_SEARCH_BY_PART_ID + searchValue
    } else {
        queryPath = APIPaths.COMPONENT_DETAILS_SEARCH_BY_COMPONENT_ID + searchValue
    }
    apiClient
        .get(queryPath)
        .then((response) => {
            setOutput(response.data)
            if (response.data.length === 0) {
                setEmptyResponse(true)
                setFlashBarItems([
                    {
                        type: AlertTypes.ERROR,
                        content: `No data found for search value: ${searchValue}. Please check the search term again`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setFlashBarItems([]),
                        id: `${searchValue}_NoDataFoundMsg`,
                    },
                ])
            }
            setOutputVisible(true)
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
            setOutput([])
            setOutputVisible(false)
        })
}

export const getMfgPnVerifiedByUser = (
    apiClient,
    mfgOptions,
    setOutput,
    arPnValue,
    currentUser,
    setMfgPnSearchStatus,
) => {
    const queryPath = APIPaths.COMPONENT_DETAILS_SEARCH_BY_PART_ID + arPnValue
    apiClient
        .get(queryPath)
        .then((response) => {
            getMfgPnInRequestByUser(
                apiClient,
                extractMgfPnVerified(response.data),
                setOutput,
                arPnValue,
                currentUser,
                setMfgPnSearchStatus,
            )
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
            setMfgPnSearchStatus(LoadingState.ERROR)
        })
}

function extractMgfPnVerified(componentData) {
    return [
        ...componentData.map((component) => ({
            label: component.component_id,
            value: component.category_id.concat(component.component_id),
            status: 'verified',
        })),
    ]
}

function extractMgfPnRequestByUser(componentData, userAlias) {
    return componentData
        .filter(
            (component) =>
                component.status === RequestStatus.NEW &&
                component.type != RequestType.DELETE &&
                component.requested_by === userAlias,
        )
        .map((component) => ({
            label: component.component_id,
            value: component.category_id.concat(component.uuid),
            status: 'review',
        }))
}

export const getMfgPnInRequestByUser = (
    apiClient,
    mfgOptions,
    setOutput,
    arPnValue,
    currentUser,
    setMfgPnSearchStatus,
) => {
    const queryPath = APIPaths.REQUEST_DETAILS_SEARCH_BY_PART_ID + arPnValue
    apiClient
        .get(queryPath)
        .then((response) => {
            const allOptions = [
                { label: LabelsForComponentIDDropdownCategories.VERIFIED, options: mfgOptions },
                {
                    label: LabelsForComponentIDDropdownCategories.IN_REVIEW,
                    options: extractMgfPnRequestByUser(response.data, currentUser),
                },
            ]
            setOutput(allOptions)
            setMfgPnSearchStatus(LoadingState.FINISHED)
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
            setMfgPnSearchStatus(LoadingState.ERROR)
        })
}

export const getDetailsInRequest = (
    apiClient,
    setMfnValue,
    setAsOriginalEntry,
    setAlternateComponent,
    setToolTips,
    arPnValue,
    setReviewUuid,
    setRequestId,
    mfgPnValue,
) => {
    const queryPath = APIPaths.REQUEST_DETAILS_SEARCH_BY_PART_ID + arPnValue
    apiClient
        .get(queryPath)
        .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].component_id === mfgPnValue) {
                    setRequestId(response.data[i].request_id)
                    setMfnValue(response.data[i].manufacturer_name)
                    setAsOriginalEntry(response.data[i].isPreferredAlternative)
                    setToolTips(response.data[i].attributes)
                    setAlternateComponent(setAlternative(response.data[i].acceptableAlternative))
                    setReviewUuid(response.data[i].uuid)
                    break
                }
            }
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
        })
}

function setAlternative(acceptableAlternative) {
    if (
        acceptableAlternative.toLowerCase() === 'yes' ||
        acceptableAlternative.toLowerCase() === 'original'
    ) {
        return { label: AcceptableAltValues.YES, value: 'YES' }
    } else if (acceptableAlternative.toLowerCase() === 'no') {
        return { label: AcceptableAltValues.NO, value: 'NO' }
    } else if (acceptableAlternative.toLowerCase() === 'deviation') {
        return { label: AcceptableAltValues.DEVIATION, value: 'DEVIATION' }
    } else if (acceptableAlternative.toLowerCase() === 'in_process') {
        return { label: AcceptableAltValues.IN_PROCESS, value: 'IN_PROCESS' }
    } else {
        return { label: '', value: '' }
    }
}

export const getDetailsInVerified = (
    apiClient,
    setMfnValue,
    setAsOriginalEntry,
    setAlternateComponent,
    setToolTips,
    arPnValue,
    mfgPnValue,
) => {
    const queryPath = APIPaths.COMPONENT_DETAILS_SEARCH_BY_COMPONENT_ID + mfgPnValue
    apiClient
        .get(queryPath)
        .then((response) => {
            setMfnValue(response.data[0].manufacturer_name)
            setAlternateComponent(setAlternative(response.data[0].AcceptableAlt))
            setAsOriginalEntry(response.data[0].isPreferredAlternative)
            setToolTips(extractAttributes(response.data[0]))
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
        })
}

function extractAttributes(componentData) {
    const attributeList: toolTipsProps[] = []
    Object.keys(componentData).forEach(function (key) {
        const attribute_label = key
        key = key.toLowerCase()

        if (
            key != 'category_id' &&
            key != 'agile' &&
            key != 'status' &&
            key != 'refdes' &&
            key != 'AcceptableAlt' &&
            key != 'isPreferredAlternative' &&
            key != 'category_id_part_id' &&
            key != 'part_id' &&
            key != 'manufacturer' &&
            key != 'component_id'
        ) {
            attributeList.push({
                label: attribute_label,
                unit: '',
                description: '',
                value: componentData[attribute_label],
                type: checkType(componentData[attribute_label]),
                isInvalid: false,
            })
        }
    })
    return attributeList
}

export const copyRequestToComponentDetails = (apiClient, payload, setError) => {
    postOperation(apiClient, APIPaths.COMPONENT_DETAILS_ADD_COMPONENT, payload)
        .then((response) => {
            setError(false)
        })
        .catch((error) => {
            console.error(`Error while copying request to component master table: ${error}`)
            setError(true)
        })
}

const postOperation = (apiClient, path, payload) => {
    return apiClient.post(path, JSON.stringify(payload))
}

export const deleteComponent = (apiClient, categoryIDPartID, componentId, setError) => {
    const payload: DeleteComponentPayload = {
        category_id_part_id: categoryIDPartID,
        component_id: componentId,
    }

    postOperation(apiClient, APIPaths.COMPONENT_DETAILS_DELETE_COMPONENT, payload)
        .then((response) => {
            setError(false)
        })
        .catch((error) => {
            console.error(`Error implementing delete request on component master table: ${error}`)
            setError(true)
        })
}
