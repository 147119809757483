import React, { useEffect, useState } from 'react'
import {
    SpaceBetween,
    Header,
    Container,
    Box,
    ColumnLayout,
    StatusIndicator,
    Popover,
    Flashbar,
    ContentLayout,
} from '@amzn/awsui-components-react/polaris'

import { useAppContext } from '../../context'
import { getSearchQueryOutput } from '../reusable/database/ComponentDetailsTableQueries'
import {
    attributeRenames,
    attributesToHide,
    getAttributeValue,
    metadataAttributesList,
} from './SearchOutputTable'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { PageDescriptions } from '../reusable/constants/Identifiers'

export function getAttributeName(attributeName) {
    return attributeName in attributeRenames ? attributeRenames[attributeName] : attributeName
}

const SearchDetail = (props) => {
    const component_id = props.match.params.id
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [searchDetailResponse, setSearchDetailResponse] = useState([])
    const [emptyResponse, setEmptyResponse] = useState(false)
    const [flashBarItems, setFlashBarItems] = React.useState([])
    const [outputVisible, setOutputVisible] = useState(false)

    useEffect(() => {
        setFlashBarItems([])
        getSearchQueryOutput(
            apiClient,
            component_id,
            setSearchDetailResponse,
            setFlashBarItems,
            setEmptyResponse,
            setOutputVisible,
        )
    }, [])

    const ValueWithLabel = ({ label, children }) => (
        <div>
            <Box variant='awsui-key-label'>
                <SpaceBetween direction='horizontal' size={'xs'}>
                    {label}
                    <Popover
                        dismissButton={false}
                        position='top'
                        size='small'
                        triggerType='custom'
                        content={'Here we would have content that describes a particular attribute'}
                    >
                        <StatusIndicator type='info'></StatusIndicator>
                    </Popover>
                </SpaceBetween>
            </Box>
            <div>{children}</div>
        </div>
    )

    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Search', href: '/search' },
                        { text: `${component_id}`, href: '' },
                    ]}
                    header={'Component Details'}
                    headerDescription={PageDescriptions.SEARCH_DETAIL}
                />
            }
        >
            <SpaceBetween size='xl'>
                {outputVisible && emptyResponse ? <Flashbar items={flashBarItems} /> : <></>}
                {outputVisible && !emptyResponse ? (
                    <SpaceBetween size='xl'>
                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    description='This is the metadata we store for a component'
                                >
                                    Component Descriptors
                                </Header>
                            }
                        >
                            <Box>
                                <ColumnLayout columns={3} variant='text-grid'>
                                    {metadataAttributesList.map((value) => (
                                        <SpaceBetween size={'l'}>
                                            <ValueWithLabel label={getAttributeName(value)}>
                                                {getAttributeValue(searchDetailResponse[0][value])}
                                            </ValueWithLabel>
                                        </SpaceBetween>
                                    ))}
                                </ColumnLayout>
                            </Box>
                        </Container>
                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    description='These are physical descriptors for a component'
                                >
                                    Component Attributes
                                </Header>
                            }
                        >
                            <Box>
                                <ColumnLayout columns={3} variant='text-grid'>
                                    {Object.entries(searchDetailResponse[0]).map(([key, value]) =>
                                        value &&
                                        value !== '' &&
                                        !metadataAttributesList.includes(key) &&
                                        !attributesToHide.includes(getAttributeName(key)) ? (
                                            <SpaceBetween size={'l'}>
                                                <ValueWithLabel label={key}>{value}</ValueWithLabel>
                                            </SpaceBetween>
                                        ) : (
                                            <></>
                                        ),
                                    )}
                                </ColumnLayout>
                            </Box>
                        </Container>
                    </SpaceBetween>
                ) : (
                    <></>
                )}
            </SpaceBetween>
        </ContentLayout>
    )
}

export default SearchDetail
