import { RequestDecisionPayload } from '../../../openapi_server'
import { APIPaths } from '../constants/ApiPaths'

export const getPendingRequests = (apiClient, setCurrentRequest, setOutput, setOutputVisible) => {
    apiClient
        .get(APIPaths.ADMIN_GET_PENDING_REQUESTS)
        .then((response) => {
            setOutput(response.data)
            setOutputVisible(true)
            if (response.data.length > 0) {
                setCurrentRequest(response.data[0])
            }
        })
        .catch((error) => {
            console.error(`Error while getting Pending requests: ${error}`)
            setOutputVisible(false)
        })
}

export const putDecisionOnRequest = (
    apiClient,
    payload: RequestDecisionPayload,
    setErrorOnDecisionSubmission: Function,
) => {
    putOperation(apiClient, APIPaths.ADMIN_POST_DECISION, payload)
        .then((response) => {
            setErrorOnDecisionSubmission(false)
        })
        .catch((error) => {
            console.error(`Error while submitting decision on request: ${error}`)
            setErrorOnDecisionSubmission(true)
        })
}

const putOperation = (apiClient, path, payload) => {
    return apiClient.put(path, JSON.stringify(payload))
}
