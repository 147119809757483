import { toolTipsProps } from '../../add/AddComponentPage'

function getTooltipWithoutBlankAttributes(toolTips: toolTipsProps[]) {
    return toolTips.filter((attribute) => attribute['value'])
}

export function cleanupToolTips(toolTips: toolTipsProps[]) {
    let tooltips: toolTipsProps[] = getTooltipWithoutBlankAttributes(toolTips)
    tooltips.forEach((attribute) => delete attribute.isInvalid)
    return tooltips
}
