import {InputType} from '../constants/Identifiers'
import {toolTipsProps} from '../../add/AddComponentPage'

export function verifyInvalid(attributes: toolTipsProps[], acceptableAlternative: string, asOriginalEntry: boolean) {
    for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].isInvalid) {
            return true
        }
    }
    return (!asOriginalEntry && acceptableAlternative === '') || (asOriginalEntry && acceptableAlternative !== '');

}

export function checkType(value) {
    if (!isNaN(value) && value) return InputType.DECIMAL
    return InputType.STRING
}
