import TopNavigation, {
    TopNavigationProps,
} from '@amzn/awsui-components-react/polaris/top-navigation'

import { useHistory } from 'react-router-dom'

import './TopNavBar.scss'
import { useAppContext } from '../../context'
import {
    SJ_MAIL,
    SJ_REPORT_BUG_URL,
    SJ_REQUEST_FEATURE_URL,
    SJ_SLACK,
    SJ_USER_GUIDE,
    SJ_VIDEOS_PLAYLIST,
    SJ_WIKI,
    UserRoles,
} from '../reusable/constants/Identifiers'
import { userHasRole } from '../../hooks/userHasRole'

const TopNavbar = () => {
    const history = useHistory()
    const { userAlias, logo } = useAppContext()
    const isAdmin = userHasRole([UserRoles.ADMIN])

    const handleClickMenuButton = (e) => {
        e.preventDefault()
        if (e.detail['href'] === undefined) {
            history.push('/')
            return
        }
        history.push(e.detail.href)
    }

    const utilities: TopNavigationProps.Utility[] = [
        {
            type: 'menu-dropdown',
            text: 'Support',
            iconName: 'status-info',
            items: [
                {
                    id: 'support-group',
                    text: 'Support Center',
                    items: [
                        {
                            id: 'feature-request',
                            text: 'Request a Feature',
                            href: SJ_REQUEST_FEATURE_URL,
                            iconName: 'edit',
                        },
                        {
                            id: 'bug-report',
                            text: 'Report a Bug',
                            href: SJ_REPORT_BUG_URL,
                            iconName: 'bug',
                        },
                    ],
                },
                {
                    id: 'learn-group',
                    text: 'Learn about Scrub Jay',
                    items: [
                        {
                            id: 'user-guide',
                            text: 'Step-by-Step User Guide ',
                            external: true,
                            href: SJ_USER_GUIDE,
                        },
                        {
                            id: 'video-tutorials',
                            text: 'Scrub Jay Video Tutorials',
                            external: true,
                            href: SJ_VIDEOS_PLAYLIST,
                        },
                        {
                            id: 'wiki-info',
                            text: 'Scrub Jay Wiki',
                            external: true,
                            href: SJ_WIKI,
                        },
                    ],
                },
                {
                    id: 'contact-group',
                    text: 'Stay Connected',
                    items: [
                        {
                            id: 'sj_email',
                            text: 'Email Us',
                            external: true,
                            href: SJ_MAIL,
                        },
                        {
                            id: 'sj_slack',
                            text: 'Join our Slack Channel',
                            external: true,
                            href: SJ_SLACK,
                        },
                    ],
                },
            ],
        },
        {
            type: 'menu-dropdown',
            text: `Hello, ${userAlias}`,
            onItemClick: handleClickMenuButton,
            items: [
                {
                    id: 'drafts',
                    text: 'Drafts (Coming soon)',
                },
                {
                    id: 'trackRequest',
                    text: 'Track your request (Coming soon)',
                },
            ],
        },
    ]

    if (isAdmin) {
        utilities.unshift({
            type: 'button',
            text: 'Admin Portal',
            ariaLabel: 'Admin portal',
            iconName: 'user-profile',
            href: '/admin',
            external: false,
        })
    }

    return (
        <div id='topNav' className='navbar' style={{ position: 'sticky', zIndex: 1002 }}>
            <TopNavigation
                identity={{
                    href: '/',
                    title: 'Scrub Jay - Alternate Component Repository',
                    logo: {
                        src: logo,
                        alt: 'Service',
                    },
                    onFollow: handleClickMenuButton,
                }}
                utilities={utilities}
                i18nStrings={{
                    searchIconAriaLabel: 'Search',
                    searchDismissIconAriaLabel: 'Close search',
                    overflowMenuTriggerText: 'More',
                    overflowMenuTitleText: 'All',
                    overflowMenuBackIconAriaLabel: 'Back',
                    overflowMenuDismissIconAriaLabel: 'Close menu',
                }}
            />
        </div>
    )
}

export default TopNavbar
