import { LinkCellRendererProps } from '@amzn/unified-data-table-components/core'
import React from 'react'
import { SpaceBetween } from '@amzn/awsui-components-react/polaris'
import useStore from '../Store'
import '@amzn/unified-data-table-components/styles/css/udc-theme.css'

import { TableDataProps } from './SearchPage'
import TropicalUITable from '../reusable/table/TropicalUITable'
import './SearchPage.scss'
import { AcceptableAltValues } from '../reusable/constants/Identifiers'
import SearchOutputSummary from './SearchOutputSummary'

const preferredComponentIdentifierAttribute = 'isPreferredAlternative'
const preferredComponentIdentifierValue = true

export function sortDataByOriginalAlt(searchResultArray) {
    let alternatesArray: TableDataProps[] = []
    let originalComponent: TableDataProps[] = []

    for (let component of searchResultArray) {
        if (
            component[preferredComponentIdentifierAttribute] === preferredComponentIdentifierValue
        ) {
            originalComponent.push(component)
        } else {
            alternatesArray.push(component)
        }
    }
    return [...originalComponent, ...alternatesArray]
}

export function getPriorityOrderForParametricAttributes(categoryDetails, givenCategoryId) {
    let priorityOrder: { [key: string]: number } = {}
    const categoryDetailList = categoryDetails.filter(
        (category) => category['category_id'] === givenCategoryId,
    )
    const categoryAttributes =
        categoryDetailList.length === 1 ? categoryDetailList[0]['attributes'] || [] : []

    for (let attribute of categoryAttributes) {
        let attributeName = attribute['unit']
            ? `${attribute['attribute_name']}(${attribute['unit']})`
            : attribute['attribute_name']
        priorityOrder[attributeName] = attribute['priority_order']
    }
    return priorityOrder
}

export function sortColumnDefinitions(columnDef, categoryId, categoryDetails) {
    const priorityOrderForParametricAttributes = getPriorityOrderForParametricAttributes(
        categoryDetails,
        categoryId,
    )

    let orderedMetadataColumnDef = Array(
        Object.keys(priorityOrderForMetadataAttributes).length,
    ).fill({})
    let orderedParametricColumnDef = Array(
        Object.keys(priorityOrderForParametricAttributes).length,
    ).fill({})

    for (let def of columnDef) {
        if (def['headerName'] in priorityOrderForMetadataAttributes) {
            orderedMetadataColumnDef[priorityOrderForMetadataAttributes[def['headerName']]] = def
        } else if (def['headerName'] in priorityOrderForParametricAttributes) {
            orderedParametricColumnDef[priorityOrderForParametricAttributes[def['headerName']]] =
                def
        } else {
            orderedParametricColumnDef.push(def)
        }
    }
    const sortedDef = orderedMetadataColumnDef.concat(orderedParametricColumnDef)
    return sortedDef.filter((def) => Object.keys(def).length !== 0)
}

// TODO: ROSS-1635 Fetch these columns from category_details table
export const metadataAttributesList = [
    'category_id',
    'category_id_part_id',
    'component_id',
    'manufacturer_name',
    'AcceptableAlt',
    'isPreferredAlternative',
    'WhereUsedPCBA',
    'Notes',
]

const priorityOrderForMetadataAttributes = {
    'Category ID': 0,
    'AR PN': 1,
    'Mfg PN': 2,
    'Manufacturer Name': 3,
    'Is Preferred Alternative': 4,
    'Is Acceptable Alternative': 5,
}

export const attributesToHide = ['part_id', 'AgileComments']

export const attributeRenames = {
    category_id: 'Category ID',
    component_id: 'Mfg PN',
    category_id_part_id: 'AR PN',
    manufacturer_name: 'Manufacturer Name',
    isPreferredAlternative: 'Is Preferred Alternative',
    AcceptableAlt: 'Is Acceptable Alternative',
}

const attributePinnedInTable = ['Category ID', 'Mfg PN', 'AR PN', 'Manufacturer Name']

export function getCategoryNameFromID(categoryDetails, id) {
    let categoryName: string = 'Unknown Category'
    for (let category of categoryDetails) {
        if (category['category_id'] === id) {
            categoryName = category['category_name']
        }
    }
    return categoryName
}

export function getAttributeValue(value) {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No'
    }
    return value || '-'
}

export function getColumnDefinitionsForSearchOutput(components) {
    let columnDefinitions: any[] = []
    let uniqueAttributesNameList: string[] = []
    let attributesNameList: string[] = []
    let originalComponent: any[] = []
    const categoryDetails = useStore((state) => state.categoryDetails)
    let countOriginalComponent = 0
    const setCountOfPreferredComponentInSearchOutput = useStore(
        (state) => state.setCountOfPreferredComponentInSearchOutput,
    )
    const setPreferredComponent = useStore((state) => state.setPreferredComponent)
    const setCountOfParametricAttributes = useStore((state) => state.setCountOfParametricAttributes)

    for (let i = 0; i < components.length; i++) {
        let component = components[i]
        // find original component
        if (
            component[preferredComponentIdentifierAttribute] === preferredComponentIdentifierValue
        ) {
            countOriginalComponent++
            originalComponent = component
        }
        // Get union of all attributeNames
        attributesNameList = [...attributesNameList, ...Object.keys(component)]
    }

    setCountOfPreferredComponentInSearchOutput(countOriginalComponent)
    if (countOriginalComponent !== 1) {
        originalComponent = []
    } else {
        setPreferredComponent(originalComponent['component_id'])
    }

    // The only types of queries we offer currently are ARPN and MFgPN based, in both cases we get output with only one category and a non-empty response
    // Hence the below assumption is safe
    // TODO: Change this when working on ROSS-2039
    const categoryIdForOutput = components[0]['category_id']

    uniqueAttributesNameList = [...new Set(attributesNameList)]

    // Get count of non-metadataAttributes i.e. Parametric attributes to display in summary
    setCountOfParametricAttributes(
        uniqueAttributesNameList.filter(
            (attribute) =>
                metadataAttributesList.indexOf(attribute) < 0 &&
                !attributesToHide.includes(attribute),
        ).length,
    )

    // Build column definitions
    for (let attributeName of uniqueAttributesNameList) {
        let definition: any
        let newAttributeName: string =
            attributeName in attributeRenames ? attributeRenames[attributeName] : attributeName
        const trimmedColId: string = attributeName.replace(/ /gi, '_')

        // ignore building column definition if the attribute is suggested to be hidden
        if (attributesToHide.includes(attributeName)) {
            continue
        }

        // default column definition properties
        definition = {
            colId: trimmedColId,
            sortable: true,
            headerName: newAttributeName,
            wrapText: true,
            autoHeight: true,
            resizable: true,
            editable: false,
        }

        if (attributeName === 'component_id') {
            // We need to add search page URL to
            definition.cellStyle = { 'font-size': '13px' }
            definition.cellRenderer = 'LinkCellRenderer'
            definition.cellRendererParams = {
                getHref: (params: LinkCellRendererProps): string =>
                    `/search/${params.data[trimmedColId]}`,
                renderMainContent: (params: any): HTMLElement => {
                    const dom = document.createElement('a')
                    dom.href = `/search/${params.data[trimmedColId]}`
                    dom.target = '_blank'
                    dom.innerHTML = params.data[trimmedColId]
                    return dom
                },
            }
            definition.field = attributeName
        } else {
            definition.cellStyle = (params: any): any => {
                if (!params.value || !originalComponent[params.colDef.colId]) {
                    return {}
                }
                if (
                    params.data[preferredComponentIdentifierAttribute] !=
                        preferredComponentIdentifierValue &&
                    !metadataAttributesList.includes(params.colDef.colId) &&
                    originalComponent.length !== 0 &&
                    (params.value !== '-' && originalComponent[params.colDef.colId] !== null) &&
                    params.value.toLowerCase() !== originalComponent[params.colDef.colId].toLowerCase()
                ) {
                    return { backgroundColor: '#b1fc99', 'font-size': '13px' }
                } else {
                    return { 'font-size': '13px' }
                }
            }
            definition.cellRenderer = 'TextCellRenderer'
            definition.valueGetter = (params: any): any => {
                if (attributeName === 'category_id') {
                    return getCategoryNameFromID(categoryDetails, params.data[trimmedColId]) || '-'
                } else if (attributeName === 'AcceptableAlt') {
                    return AcceptableAltValues.valueOf(params.data[trimmedColId])
                } else {
                    return getAttributeValue(params.data[trimmedColId])
                }
            }
        }

        if (attributePinnedInTable.includes(definition.headerName)) {
            definition.pinned = 'start'
        }
        columnDefinitions.push(definition)
    }
    return sortColumnDefinitions(columnDefinitions, categoryIdForOutput, categoryDetails)
}

const SearchOutputTable = (props) => {
    const { searchValue, searchResultArray } = props
    return (
        <SpaceBetween size={'xl'}>
            <SearchOutputSummary />
            <TropicalUITable
                tableName='ScrubJaySearchOutputTable'
                exportFileName={'ScrubJaySearchOutputFor' + searchValue}
                data={sortDataByOriginalAlt(searchResultArray)}
                columnDefinitionsGenerator={getColumnDefinitionsForSearchOutput}
            />
        </SpaceBetween>
    )
}

export default SearchOutputTable
