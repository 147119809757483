import React from 'react'
import { APIPaths } from './reusable/constants/ApiPaths'
import { LoadingState } from './reusable/constants/Identifiers'

function enableOutputAdd(setOutput, onAdd, setMfgVerified) {
    setOutput(true)
    onAdd(false)
    setMfgVerified(false)
}

function disableOutputAdd(setOutput, onAdd, setMfgVerified) {
    setOutput(false)
    onAdd(true)
    setMfgVerified(true)
}

export const getCategories = (apiClient, setOutput) => {
    apiClient.get(APIPaths.CATEGORY_DETAILS_GET_ALL).then((response) => {
        setOutput(extractCategories(response.data))
    })
}

export const getArPnByCategory = (apiClient, setOutput, category, setArPnStatus) => {
    const queryPath = APIPaths.CATEGORY_DETAILS_GET_ARPN_BY_CATEGORY_ID + category
    apiClient
        .get(queryPath)
        .then((response) => {
            setOutput(setUniqueArPn(response.data))
            setArPnStatus(LoadingState.FINISHED)
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
            setArPnStatus(LoadingState.ERROR)
        })
}

export const verifyMfgPnExists = (apiClient, setOutput, onAdd, arPN, mfgPn, setMfgVerified) => {
    const queryPath = APIPaths.COMPONENT_DETAILS_SEARCH_BY_PART_ID + arPN
    apiClient
        .get(queryPath)
        .then((response) => {
            const MfgFilteredData = response.data.filter(
                (component) => component['component_id'] === mfgPn,
            )
            MfgFilteredData.length > 0
                ? enableOutputAdd(setOutput, onAdd, setMfgVerified)
                : disableOutputAdd(setOutput, onAdd, setMfgVerified)
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
        })
}

export const getComponentToolTips = (apiClient, setToolTips, searchValue) => {
    apiClient.get(APIPaths.CATEGORY_DETAILS_GET_ALL).then((response) => {
        setToolTips(extractToolTips(response.data, searchValue))
    })
}

function extractToolTips(categoryData, searchValue) {
    const toolTips = []
    if (!categoryData && !searchValue) {
        return toolTips
    }
    for (let i = 0; i < categoryData.length; i++) {
        if (categoryData[i].category_id === searchValue) {
            return categoryData[i].attributes.map((data) => ({
                label: data.attribute_name,
                unit: data.unit,
                description: data.attribute_description,
                type: data.type,
            }))
        }
    }
    return toolTips
}

function extractCategories(categoryData) {
    const allCategories = [
        ...categoryData.map((category) => ({
            label: category.category_id.concat('-').concat(category.category_name),
            value: category.category_id,
        })),
    ]
    allCategories.sort(function (category1, category2) {
        return category1.label === category2.label ? 0 : category1.label < category2.label ? -1 : 1
    })
    return allCategories
}

function setUniqueArPn(arPnData) {
    const sortedData = arPnData.sort(
        new Intl.Collator('en', { numeric: true, sensitivity: 'accent' }).compare,
    )
    return [
        ...sortedData.map((arPn) => ({
            value: arPn,
        })),
    ]
}
