import {
    Alert,
    Autosuggest,
    AutosuggestProps,
    Box,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    Select,
    SpaceBetween,
    Toggle,
} from '@amzn/awsui-components-react/polaris'
import React, { useEffect } from 'react'
import AddFormField from '../forms/AddFormField'
import FormFieldSecondaryLabel from '../forms/FormFieldSecondaryLabel'
import { getComponentToolTips } from '../../CategoryDao'
import { useAppContext } from '../../../context'
import { alternateComponentProps, toolTipsProps } from '../../add/AddComponentPage'
import {
    AcceptableAltValues,
    InputType,
    LabelsForComponentIDDropdownCategories,
    STRING,
} from '../constants/Identifiers'
import { categoryProps } from '../interfaces/Interfaces'
import { getParameterOptions, getParameterUnits } from '../database/attributesDetailsTableQueries'

const INVALID_PARAMETER_NAME_ERROR = 'Please enter a valid parameter name'
const INVALID_PARAMETER_UNIT_ERROR = 'Please enter a valid unit'

export interface editDetailsProps {
    status?: string
    mfnValue: string
    setMfnValue: any
    asOriginalEntry: boolean
    setAsOriginalEntry: any
    toolTips: toolTipsProps[]
    setToolTips: any
    alternateComponent: alternateComponentProps
    setAlternateComponent: any
    requestPage: string
    searchValue?: string
}

const ComponentDetailEdit = (props: editDetailsProps) => {
    const [modalVisible, setModalVisible] = React.useState(false)

    // To add a new parameter
    const [newParameter, setNewParameter] = React.useState('')
    const [newParameterUnit, setNewParameterUnit] = React.useState('')
    const [newParameterDesc, setNewParameterDesc] = React.useState('')
    const [newParameterType, setNewParameterType] = React.useState(InputType.DECIMAL)
    const [invalidNameError, setInvalidNameError] = React.useState('')
    const [invalidUnitError, setInvalidUnitError] = React.useState('')
    const [inputStatus, setInputStatus] = React.useState(true)
    const [selectedParameterUnit, setSelectedParameterUnits] =
        React.useState<AutosuggestProps.Options>([])
    const [parameterOptions, setParameterOptions] = React.useState([])

    const [selectedType, setSelectedType] = React.useState<categoryProps>({
        label: 'Decimal',
        value: InputType.DECIMAL,
    })

    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const nonTextBasedAttributes: string[] = [
        'manufacturer_name',
        'isPreferredAlternative',
        'Acceptable Alternative',
    ]

    const addFields = (label, unit, description, type) => {
        const newParameter = {
            label: label,
            unit: unit,
            description: description,
            value: '',
            type: type,
            isInvalid: false,
        }
        props.setToolTips([...props.toolTips, newParameter])
    }

    const handleFormChange = (data, index, isInvalid) => {
        const allToolTips = [...props.toolTips]
        allToolTips[index].value = data
        allToolTips[index].isInvalid = isInvalid
    }

    if (props.requestPage === 'add') {
        useEffect(() => {
            getComponentToolTips(apiClient, props.setToolTips, props.searchValue)
        }, [])
    }

    const validateInputs = (value: string, setError: Function, message: string) => {
        value.trim().length === 0 ? setError(message) : setError('')
    }

    const validatePayload = () => {
        let flag = true
        if (newParameter.trim().length === 0) {
            setInvalidNameError(INVALID_PARAMETER_NAME_ERROR)
            flag = false
        }
        if (newParameterUnit.trim().length === 0) {
            setInvalidUnitError(INVALID_PARAMETER_UNIT_ERROR)
            flag = false
        }
        return flag
    }

    const dismissModal = () => {
        setModalVisible(false)
        setNewParameter('')
        setNewParameterUnit('')
        setInvalidNameError('')
        setInvalidUnitError('')
    }
    return (
        <Container>
            <SpaceBetween direction='vertical' size='xl'>
                {props.requestPage === 'modify' ? (
                    props.status === 'review' ? (
                        <Alert
                            header={`Part Status: ${LabelsForComponentIDDropdownCategories.IN_REVIEW}`}
                        ></Alert>
                    ) : (
                        <Alert
                            header={`Part Status: ${LabelsForComponentIDDropdownCategories.VERIFIED}`}
                        ></Alert>
                    )
                ) : (
                    <></>
                )}
                <Header variant='h3'> Details </Header>
                <ColumnLayout columns={3}>
                    <Box>
                        <FormFieldSecondaryLabel
                            mainContent={'Manufacturer'}
                            popover={'Manufacturer details'}
                        />
                        <Input
                            onChange={({ detail }) => {
                                props.setMfnValue(detail.value)
                            }}
                            value={props.mfnValue}
                        />
                    </Box>
                </ColumnLayout>
                <ColumnLayout columns={3}>
                    <Box>
                        <FormFieldSecondaryLabel
                            mainContent={'Set as Original entry'}
                            popover={'Add this component as an original entry'}
                        />
                        <Toggle
                            onChange={({ detail }) => {
                                props.setAsOriginalEntry(detail.checked)
                                props.setAlternateComponent({
                                    label: '',
                                    value: '',
                                })
                            }}
                            checked={props.asOriginalEntry}
                        ></Toggle>
                    </Box>

                    {!props.asOriginalEntry ? (
                        <Box>
                            <FormFieldSecondaryLabel
                                mainContent={'Acceptable Alternative'}
                                popover={
                                    'Is this an acceptable alternative for an original component?'
                                }
                            />
                            <Select
                                selectedOption={props.alternateComponent}
                                invalid={!props.asOriginalEntry && props.alternateComponent.value === ''}
                                onChange={({ detail }) => {
                                    props.setAlternateComponent(detail.selectedOption)
                                    }
                                }
                                options={[
                                    {
                                        label: AcceptableAltValues.YES,
                                        value: 'YES',
                                    },
                                    {
                                        label: AcceptableAltValues.NO,
                                        value: 'NO',
                                    },
                                    {
                                        label: AcceptableAltValues.DEVIATION,
                                        value: 'DEVIATION',
                                    },
                                    {
                                        label: AcceptableAltValues.IN_PROCESS,
                                        value: 'IN_PROCESS',
                                    },
                                ]}
                                selectedAriaLabel='Selected'
                            />
                        </Box>
                    ) : (
                        <></>
                    )}
                </ColumnLayout>
                <ColumnLayout columns={3}>
                    {props.toolTips.map((recommendedAttributes, index) => {
                        if (nonTextBasedAttributes.includes(recommendedAttributes.label)) {
                            return <></>
                        }

                        return (
                            <Box key={index}>
                                <AddFormField
                                    header={recommendedAttributes.label}
                                    content={recommendedAttributes.description}
                                    placeholder={recommendedAttributes.unit}
                                    setValue={index}
                                    value={recommendedAttributes.value}
                                    handleInput={handleFormChange}
                                    constraintType={recommendedAttributes.type}
                                    inputStatus={setInputStatus}
                                />
                            </Box>
                        )
                    })}
                </ColumnLayout>
                <Modal
                    className='awsui-visual-refresh'
                    onDismiss={() => dismissModal()}
                    visible={modalVisible}
                    closeAriaLabel='Close modal'
                    footer={
                        <Box float='right'>
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button variant='link' onClick={() => dismissModal()}>
                                    Cancel
                                </Button>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        if (validatePayload()) {
                                            const type =
                                                selectedType.value === InputType.DECIMAL
                                                    ? InputType.DECIMAL
                                                    : STRING
                                            addFields(
                                                newParameter,
                                                newParameterUnit,
                                                newParameterDesc,
                                                type,
                                            )
                                            setModalVisible(false)
                                            setNewParameter('')
                                            setNewParameterUnit('')
                                        }
                                    }}
                                >
                                    Add
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header='Add new Parameter'
                >
                    <SpaceBetween direction='vertical' size='m'>
                        <ColumnLayout columns={2}>
                            <FormField
                                label='Parameter Name'
                                description='Enter the new parameter name'
                                errorText={invalidNameError}
                            >
                                <Autosuggest
                                    onChange={({ detail }) => {
                                        setNewParameter(detail.value)
                                        validateInputs(
                                            detail.value,
                                            setInvalidNameError,
                                            INVALID_PARAMETER_NAME_ERROR,
                                        )
                                    }}
                                    value={newParameter}
                                    options={parameterOptions}
                                    enteredTextLabel={(value) => `Use: "${value}"`}
                                    ariaLabel='Autosuggest parameter suggestions'
                                    placeholder='Enter parameter'
                                    empty='No matches found'
                                />
                            </FormField>
                            <FormField
                                label='Parameter Unit'
                                description='Enter the new parameter unit'
                                errorText={invalidUnitError}
                            >
                                <Autosuggest
                                    onChange={({ detail }) => {
                                        setNewParameterUnit(detail.value)
                                        validateInputs(
                                            detail.value,
                                            setInvalidUnitError,
                                            INVALID_PARAMETER_UNIT_ERROR,
                                        )
                                    }}
                                    onFocus={() => {
                                        getParameterUnits(
                                            apiClient,
                                            newParameter,
                                            setSelectedParameterUnits,
                                        )
                                    }}
                                    value={newParameterUnit}
                                    options={selectedParameterUnit}
                                    enteredTextLabel={(value) => `Use: "${value}"`}
                                    ariaLabel='Autosuggest unit suggestions'
                                    placeholder='Enter parameter unit'
                                    empty='No matches found'
                                />
                            </FormField>
                        </ColumnLayout>
                        <FormField label='Description' description='Enter the description'>
                            <Input
                                onChange={({ detail }) => setNewParameterDesc(detail.value)}
                                value={newParameterDesc}
                                placeholder='Enter parameter description'
                            />
                        </FormField>
                        <FormField label='Input Type' description='Enter Unit Type'>
                            <Select
                                selectedOption={selectedType}
                                onChange={({ detail }) => {
                                    setSelectedType(detail.selectedOption as categoryProps)
                                }}
                                options={[
                                    {
                                        label: 'Decimal',
                                        value: InputType.DECIMAL,
                                    },
                                    {
                                        label: 'String',
                                        value: InputType.STRING,
                                    },
                                ]}
                                selectedAriaLabel='Selected'
                            />
                        </FormField>
                    </SpaceBetween>
                </Modal>
                <Button
                    onClick={() => {
                        setModalVisible(true)
                        getParameterOptions(apiClient, setParameterOptions)
                    }}
                >
                    {' '}
                    <b>+</b> Add parameter
                </Button>
            </SpaceBetween>
        </Container>
    )
}

export default ComponentDetailEdit
