import useStore from '../Store'
import {
    Badge,
    Box,
    Button,
    ColumnLayout,
    Container,
    Header,
    Popover,
    SpaceBetween,
    StatusIndicator,
    TextContent,
} from '@amzn/awsui-components-react'
import { BadgeColor, SearchPageSummaryPointsTooltip } from '../reusable/constants/Identifiers'
import React from 'react'

const ValueWithLabel = ({ label, popoverContent, children }) => (
    <Box>
        <Box variant='awsui-key-label'>
            <SpaceBetween direction='horizontal' size={'xs'}>
                {label}
                <Popover
                    dismissButton={false}
                    position='top'
                    size='small'
                    triggerType='custom'
                    content={`${popoverContent}`}
                >
                    <StatusIndicator type='info'></StatusIndicator>
                </Popover>
            </SpaceBetween>
        </Box>
        <Box>{children}</Box>
    </Box>
)

function getBadgeColor(count) {
    return count > 1 ? BadgeColor.BLUE : count === 1 ? BadgeColor.GREEN : BadgeColor.RED
}

const SearchOutputSummary = (props) => {
    const countOfPreferredComponentInSearchOutput = useStore(
        (state) => state.countOfPreferredComponentInSearchOutput,
    )
    const preferredComponent = useStore((state) => state.preferredComponent)
    const countOfParametricAttributes = useStore((state) => state.countOfParametricAttributes)
    const badgeColorNumPreferredComponent = getBadgeColor(countOfPreferredComponentInSearchOutput)

    return (
        <Container
            header={
                <Header
                    variant='h3'
                    description='This section contains overview of the components displayed in output section'
                >
                    Output Summary
                </Header>
            }
        >
            <SpaceBetween size={'l'}>
                <ColumnLayout columns={3} variant='text-grid'>
                    <ValueWithLabel
                        label={'Number of Preferred Components'}
                        popoverContent={SearchPageSummaryPointsTooltip.NUM_OF_PREFERRED_COMPONENT}
                    >
                        <Badge color={badgeColorNumPreferredComponent}>
                            {countOfPreferredComponentInSearchOutput}
                        </Badge>
                    </ValueWithLabel>
                    {countOfPreferredComponentInSearchOutput === 1 ? (
                        <ValueWithLabel
                            label={'Preferred component'}
                            popoverContent={SearchPageSummaryPointsTooltip.PREFERRED_COMPONENT}
                        >
                            <Box margin={{ right: 'xxs' }} display='inline-block'>
                                <Popover
                                    size='small'
                                    position='top'
                                    triggerType='custom'
                                    dismissButton={false}
                                    content={
                                        <StatusIndicator type='success'>
                                            Detail page link copied
                                        </StatusIndicator>
                                    }
                                >
                                    <Button
                                        iconName='copy'
                                        variant='inline-icon'
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                `prod.scrubjay.birds.amazon.dev/search/${preferredComponent}`,
                                            )
                                        }
                                    ></Button>
                                </Popover>
                            </Box>
                            {preferredComponent}
                        </ValueWithLabel>
                    ) : (
                        <></>
                    )}
                    <ValueWithLabel
                        label={'Number of Parametric attributes'}
                        popoverContent={SearchPageSummaryPointsTooltip.NUM_OF_PARAMETRIC_ATTRIBUTES}
                    >
                        <TextContent>{countOfParametricAttributes}</TextContent>
                    </ValueWithLabel>
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    )
}

export default SearchOutputSummary
