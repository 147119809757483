import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    SpaceBetween,
    Autosuggest,
    ColumnLayout,
    Container,
    Form,
    Select,
    SelectProps,
    StatusIndicator,
    Modal,
    ContentLayout,
    Textarea,
    Flashbar,
} from '@amzn/awsui-components-react/polaris'
import { useAppContext } from '../../context'
import AddComponentMfg from './AddComponentMfg'
import { getCategories, getArPnByCategory } from '../CategoryDao'
import FormFieldLabel from '../reusable/forms/FormFieldSecondaryLabel'
import FormFieldPrimaryHeader from '../reusable/forms/FormFieldPrimaryHeader'
import ComponentDetailEdit from '../reusable/componentDetail/ComponentDetailSection'
import { generateRequestFormat, generateUniqueUuId } from '../reusable/RequestFormatGenerator'
import {
    INVALID_PARAMETERS_MESSAGE,
    LoadingState,
    PageDescriptions,
    RequestStatus,
    RequestType,
    UserRoles,
} from '../reusable/constants/Identifiers'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { verifyInvalid } from '../reusable/functions/verifyAttributes'
import { cleanupToolTips } from '../reusable/functions/OperationsOnAttributes'
import { APIPaths } from '../reusable/constants/ApiPaths'
import { userHasRole } from '../../hooks/userHasRole'

export interface toolTipsProps {
    isInvalid?: boolean
    label: string
    unit: string
    description: string
    value: string
    type: string
}

export interface alternateComponentProps {
    label: string
    value: string
}

const AddComponentPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [selectedCategory, setSelectedCategory] = React.useState<SelectProps.Option>({
        label: 'Select a category',
        value: '',
    })
    const [arPnValue, setArPnValue] = useState('')
    const [isMfgSet, setIsMfgSet] = useState(false)
    const [isExistingARPn, setIsExistingARPn] = useState(false)
    const [categoryOptions, setCategoryOptions] = useState<object[]>([])
    const [currentCategory, setCurrentCategory] = useState<object[]>([])
    const [searchStatusValue, setSearchStatusValue] = useState(true)
    const [selectArPn, setSelectArPn] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [requestorNotes, setRequestorNotes] = React.useState('')
    const [showInvalidParamFlashBar, setShowInvalidParamFlashBar] = React.useState(false)
    const [arPnStatus, setArPnStatus] = useState<
        LoadingState.ERROR | LoadingState.FINISHED | LoadingState.LOADING
    >(LoadingState.LOADING)

    // Input Mfg PN component variables
    const [mfgPnValue, setMfgPnValue] = React.useState('')

    // Details component variables
    const [mfnValue, setMfnValue] = React.useState('')
    const [asOriginalEntry, setAsOriginalEntry] = React.useState(false)
    const [alternateComponent, setAlternateComponent] = React.useState<alternateComponentProps>({
        label: '',
        value: '',
    })
    const [toolTips, setToolTips] = React.useState<toolTipsProps[]>([])

    const [requestModalVisible, setRequestModalVisible] = React.useState(false)

    const isAdmin = userHasRole([UserRoles.ADMIN])

    const setSearchStatus = (values) => {
        if (!values) {
            setSearchStatusValue(true)
        } else {
            for (let i = 0; i < currentCategory.length; i++) {
                let catValues = Array(Object.values(currentCategory[i]))
                if (catValues[0][0] === values) {
                    setSearchStatusValue(false)
                    setIsExistingARPn(true)
                    break
                } else {
                    setSearchStatusValue(true)
                    setIsExistingARPn(false)
                }
            }
            if (isAdmin) {
                setSearchStatusValue(false)
            }
        }
    }

    const handleSubmit = () => {
        if (verifyInvalid(toolTips, alternateComponent.value, asOriginalEntry)) {
            setShowInvalidParamFlashBar(true)
            return
        }
        const uniqueId = generateUniqueUuId()
        const payloadRequestId = generateRequestFormat(uniqueId, mfgPnValue)
        const cleanedUpToolTip = cleanupToolTips(toolTips)
        const payload = {
            uuid: uniqueId,
            request_id: payloadRequestId,
            category: selectedCategory.label,
            category_id: selectedCategory.value,
            category_id_part_id: arPnValue,
            component_id: mfgPnValue,
            manufacturer_name: mfnValue,
            isPreferredAlternative: asOriginalEntry,
            acceptableAlternative: alternateComponent.value,
            attributes: cleanedUpToolTip,
            status: RequestStatus.NEW,
            type: RequestType.ADD,
            requestor_notes: requestorNotes,
            requested_by: appContext.userAlias,
            request_date: Date().toString(),
        }
        requestAddComponent(payload)
            .then(() => {
                setRequestId(payloadRequestId)
                setRequestModalVisible(true)
            })
            .catch((err) => {
                console.error('error ' + err)
            })
    }

    const requestAddComponent = (payload) => {
        return apiClient.post(APIPaths.REQUEST_DETAILS_ADD, JSON.stringify(payload))
    }

    useEffect(() => {
        getCategories(apiClient, setCategoryOptions)
    }, [])

    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Add', href: '' },
                    ]}
                    header={'Add a component'}
                    headerDescription={PageDescriptions.ADD}
                />
            }
        >
            <Box>
                <SpaceBetween size='xl'>
                    <SpaceBetween size='m'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            <Form>
                                <SpaceBetween direction='vertical' size='l'>
                                    <Container>
                                        <SpaceBetween size='l' direction='vertical'>
                                            <FormFieldPrimaryHeader
                                                header={'Select ARPN'}
                                                description={'AR PN is Amazon Robotics Part Number'}
                                            />
                                            <ColumnLayout columns={3}>
                                                <Box>
                                                    <FormFieldLabel
                                                        mainContent={'Category'}
                                                        popover={'Category of the new component'}
                                                    />
                                                    {categoryOptions.length === 0 ? (
                                                        <Select
                                                            selectedOption={selectedCategory}
                                                            onChange={({ detail }) =>
                                                                setSelectedCategory(
                                                                    detail.selectedOption,
                                                                )
                                                            }
                                                            options={[]}
                                                            placeholder='Choose an option'
                                                            selectedAriaLabel='Selected'
                                                            statusType='loading'
                                                            loadingText={'loading categories'}
                                                        />
                                                    ) : (
                                                        <Select
                                                            selectedOption={selectedCategory}
                                                            onChange={({ detail }) => {
                                                                setSelectedCategory(
                                                                    detail.selectedOption,
                                                                )
                                                                getArPnByCategory(
                                                                    apiClient,
                                                                    setCurrentCategory,
                                                                    detail.selectedOption.value,
                                                                    setArPnStatus,
                                                                )
                                                                setArPnValue('')
                                                                setIsMfgSet(false)
                                                                setSearchStatusValue(true)
                                                            }}
                                                            options={categoryOptions}
                                                            placeholder='Choose an option'
                                                            selectedAriaLabel='Selected'
                                                            empty='No options'
                                                        />
                                                    )}
                                                </Box>
                                                <Box>
                                                    <FormFieldLabel
                                                        mainContent={'AR PN'}
                                                        popover={'Amazon Robotics Part Number'}
                                                    />
                                                    <SpaceBetween direction='horizontal' size='xs'>
                                                        <Autosuggest
                                                            onChange={({ detail }) => {
                                                                setArPnValue(detail.value)
                                                                setIsMfgSet(false)
                                                                setSearchStatusValue(true)
                                                                setSelectArPn(false)
                                                            }}
                                                            value={arPnValue}
                                                            options={currentCategory}
                                                            virtualScroll
                                                            onSelect={(value) => {
                                                                setIsMfgSet(false)
                                                                setSearchStatus(value.detail.value)
                                                                setSelectArPn(true)
                                                            }}
                                                            enteredTextLabel={(value) =>
                                                                `Use: "${value}"`
                                                            }
                                                            ariaLabel="Autosuggest ARPN's"
                                                            loadingText="Loading ARPN's"
                                                            errorText='Error retrieving ARPN values'
                                                            statusType={arPnStatus}
                                                            placeholder='Enter value'
                                                            empty='No matches found'
                                                        />
                                                        {searchStatusValue &&
                                                            selectArPn &&
                                                            !isAdmin && (
                                                                <StatusIndicator type='error'>
                                                                    Cannot find the AR PN, please
                                                                    enter a valid AR PN from the
                                                                    list
                                                                </StatusIndicator>
                                                            )}
                                                        {!searchStatusValue &&
                                                            selectArPn &&
                                                            !isExistingARPn &&
                                                            isAdmin && (
                                                                <StatusIndicator type='warning'>
                                                                    AR PN not found, Admin Override
                                                                    applied to input Mfg PN
                                                                </StatusIndicator>
                                                            )}
                                                    </SpaceBetween>
                                                </Box>
                                            </ColumnLayout>
                                        </SpaceBetween>
                                    </Container>
                                    {!searchStatusValue && arPnValue != '' ? (
                                        <AddComponentMfg
                                            arPN={arPnValue}
                                            onAdd={setIsMfgSet}
                                            setMfgValue={setMfgPnValue}
                                            mfgValue={mfgPnValue}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {!searchStatusValue && isMfgSet && arPnValue != '' ? (
                                        <SpaceBetween size='xl'>
                                            <ComponentDetailEdit
                                                searchValue={selectedCategory.value}
                                                mfnValue={mfnValue}
                                                setMfnValue={setMfnValue}
                                                asOriginalEntry={asOriginalEntry}
                                                setAsOriginalEntry={setAsOriginalEntry}
                                                toolTips={toolTips}
                                                setToolTips={setToolTips}
                                                alternateComponent={alternateComponent}
                                                setAlternateComponent={setAlternateComponent}
                                                requestPage={'add'}
                                            />
                                            <Textarea
                                                onChange={({ detail }) =>
                                                    setRequestorNotes(detail.value)
                                                }
                                                value={requestorNotes}
                                                placeholder='Enter notes to give additional context for Admins to help approve this request'
                                            />
                                            {showInvalidParamFlashBar ? (
                                                <Flashbar
                                                    items={[
                                                        {
                                                            header: INVALID_PARAMETERS_MESSAGE,
                                                            type: 'error',
                                                            content:
                                                                'This is a dismissible error message.',
                                                            dismissible: true,
                                                            dismissLabel: 'Dismiss message',
                                                            onDismiss: () =>
                                                                setShowInvalidParamFlashBar(false),
                                                        },
                                                    ]}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </SpaceBetween>
                                    ) : (
                                        <></>
                                    )}
                                </SpaceBetween>
                            </Form>
                        </form>
                    </SpaceBetween>
                    {!searchStatusValue && isMfgSet && arPnValue != '' && (
                        <Box>
                            <SpaceBetween direction='horizontal' size='m'>
                                <Button
                                    disabled={
                                        true /*Setting this as true as it's not in scope of MLP*/
                                    }
                                >
                                    Save as draft
                                </Button>
                                {/*TODO: Simplify logic for the below check*/}
                                {/*The below checks are to display the third stage, and the third stage can only be displayed if the search status on mfg pn is verified*/}
                                {/*if there exists a Mgf Number(If empty the 3rd stage should not be displayed*/}
                                <Button
                                    disabled={
                                        !(
                                            !searchStatusValue &&
                                            isMfgSet &&
                                            arPnValue != '' &&
                                            mfnValue != ''
                                        )
                                    }
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    variant='primary'
                                >
                                    Submit
                                </Button>
                            </SpaceBetween>
                        </Box>
                    )}
                </SpaceBetween>
                <Modal
                    className='awsui-visual-refresh'
                    onDismiss={() => window.location.reload(false)}
                    visible={requestModalVisible}
                    closeAriaLabel='Close modal'
                    footer={
                        <Box float='right'>
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button href='/'>Go to home page</Button>
                                <Button variant='primary' href='/add'>
                                    Add another component
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header='Requested to add a new parameter'
                >
                    <StatusIndicator type='info'>
                        New request with Request ID: {requestId} created.
                    </StatusIndicator>
                </Modal>
            </Box>
        </ContentLayout>
    )
}

export default AddComponentPage
