import create from 'zustand'
import { devtools } from 'zustand/middleware'

let useStore: any = (set) => ({
    filteringQuery: { tokens: [], operation: 'and' },
    categoryDetails: [],
    countOfPreferredComponentInSearchOutput: 0,
    preferredComponent: '',
    countOfParametricAttributes: 0,
    setCategoryDetails: (value) =>
        set((state) => ({
            ...state,
            categoryDetails: value,
        })),
    setFilteringQuery: (value) =>
        set((state) => ({
            ...state,
            filteringQuery: value,
        })),
    setCountOfPreferredComponentInSearchOutput: (value) =>
        set((state) => ({
            ...state,
            countOfPreferredComponentInSearchOutput: value,
        })),
    setPreferredComponent: (value) =>
        set((state) => ({
            ...state,
            preferredComponent: value,
        })),
    setCountOfParametricAttributes: (value) =>
        set((state) => ({
            ...state,
            countOfParametricAttributes: value,
        })),
})

// TODO: remove it before production release
useStore = devtools(useStore)

export default useStore = create(useStore)
