import React, { useEffect, useState } from 'react'
import {
    Alert,
    Box,
    Button,
    ContentLayout,
    Form,
    Modal,
    SelectProps,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react/polaris'
import { useAppContext } from '../../context'
import { getCategories } from '../CategoryDao'
import {
    getDetailsInRequest,
    getDetailsInVerified,
    getMfgPnVerifiedByUser,
} from '../reusable/database/ComponentDetailsTableQueries'
import ComponentDetailView from '../reusable/componentDetail/ComponentDetailView'
import ComponentSearchByCategory from '../reusable/componentLookup/ComponentSearchByCategory'
import { generateRequestFormat, generateUniqueUuId } from '../reusable/RequestFormatGenerator'
import { categoryProps, MfgPnProps } from '../reusable/interfaces/Interfaces'
import {
    LoadingState,
    PageDescriptions,
    RequestStatus,
    RequestType,
} from '../reusable/constants/Identifiers'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { APIPaths } from '../reusable/constants/ApiPaths'

export interface attributesProps {
    label: string
    unit: string
    description: string
    value: string
}

const DeleteComponentPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [selectedCategory, setSelectedCategory] = React.useState<categoryProps>({
        label: 'Select a category',
        value: '',
    })
    const [categoryOptions, setCategoryOptions] = useState<categoryProps[]>([])
    const [currentArPnList, setArPnList] = useState<object[]>([])
    const [arPnValue, setArPnValue] = useState('')
    const [searchStatusValue, setSearchStatusValue] = useState(true)
    const [showMfgPN, setShowMfgPn] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [reviewUuid, setReviewUuid] = useState('')
    const [mfgPnSearchStatus, setMfgPnSearchStatus] = React.useState<
        LoadingState.ERROR | LoadingState.FINISHED | LoadingState.LOADING
    >(LoadingState.LOADING)
    const [selectedMfgPn, setSelectedMfgPn] = React.useState<MfgPnProps>({
        label: 'Select a Mfg PN',
        value: '',
        status: 'UNSET',
    })

    const [mfgPnOptions, setMfgPnOptions] = useState<object[]>([])
    useEffect(() => {
        getCategories(apiClient, setCategoryOptions)
    }, [])

    // Details component variables
    const [mfnValue, setMfnValue] = React.useState('')
    const [asOriginalEntry, setAsOriginalEntry] = React.useState(false)
    const [alternateComponent, setAlternateComponent] = React.useState<SelectProps.Option>({
        label: '',
        value: '',
    })
    const [toolTips, setToolTips] = React.useState<attributesProps[]>([])
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    const [deleteStatusVisible, setDeleteStatusVisible] = React.useState(false)
    const [requestorNotes, setRequestorNotes] = React.useState('')

    const setSearchStatus = (values) => {
        if (!values) {
            setSearchStatusValue(true)
        } else {
            for (let i = 0; i < currentArPnList.length; i++) {
                const catValues = Array(Object.values(currentArPnList[i]))
                if (catValues[0]) {
                    if (catValues[0][0] === values) {
                        setSearchStatusValue(false)
                        setShowMfgPn(true)
                        getMfgPnVerifiedByUser(
                            apiClient,
                            mfgPnOptions,
                            setMfgPnOptions,
                            values,
                            appContext.userAlias,
                            setMfgPnSearchStatus,
                        )
                        break
                    }
                }
                setShowMfgPn(false)
                setSearchStatusValue(true)
            }
        }
    }

    const handleDelete = () => {
        if (selectedMfgPn.status === 'review') {
            const deleteString = `/delete/orgId/ar/uuid/${reviewUuid}/requestId/${requestId}`
            apiClient
                .delete(deleteString)
                .then(() => setDeleteStatusVisible(true))
                .catch((err) => {
                    console.error('error ' + err)
                })
        } else {
            const uniqueId = generateUniqueUuId()
            const payloadRequestId = generateRequestFormat(
                uniqueId,
                selectedMfgPn.label.toUpperCase(),
            )
            setRequestId(payloadRequestId)
            const payload = {
                uuid: uniqueId,
                request_id: payloadRequestId,
                category: selectedCategory.label,
                category_id: selectedCategory.value,
                category_id_part_id: arPnValue,
                component_id: selectedMfgPn.label,
                manufacturer_name: mfnValue,
                isPreferredAlternative: asOriginalEntry,
                acceptableAlternative: alternateComponent.value,
                attributes: toolTips,
                type: RequestType.DELETE,
                status: RequestStatus.NEW,
                requestor_notes: requestorNotes,
                requested_by: appContext.userAlias,
                request_date: Date().toString(),
            }
            try {
                const payloadAsJson = JSON.stringify(payload)
                requestAddComponent(payloadAsJson)
                    .then(() => {
                        setDeleteStatusVisible(true)
                    })
                    .catch((err) => {
                        console.error('error ' + err)
                    })
            } catch (err) {
                console.error('error :' + err)
            }
        }
    }

    const requestAddComponent = (payload) => {
        return apiClient.post(APIPaths.REQUEST_DETAILS_ADD, payload)
    }

    const handleMfgChange = (event) => {
        setSelectedMfgPn(event.detail.selectedOption)
        if (event.detail.selectedOption.status === 'review') {
            getDetailsInRequest(
                apiClient,
                setMfnValue,
                setAsOriginalEntry,
                setAlternateComponent,
                setToolTips,
                arPnValue,
                setReviewUuid,
                setRequestId,
                event.detail.selectedOption.label,
            )
        } else {
            getDetailsInVerified(
                apiClient,
                setMfnValue,
                setAsOriginalEntry,
                setAlternateComponent,
                setToolTips,
                arPnValue,
                event.detail.selectedOption.label,
            )
        }
    }

    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Delete', href: '' },
                    ]}
                    header={'Delete a component'}
                    headerDescription={PageDescriptions.DELETE}
                />
            }
        >
            <Box>
                <SpaceBetween size='xl'>
                    <SpaceBetween size='m'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            <Form>
                                <SpaceBetween direction='vertical' size='l'>
                                    <ComponentSearchByCategory
                                        categoryOptions={categoryOptions}
                                        selectedCategory={selectedCategory}
                                        setSelectedCategory={setSelectedCategory}
                                        apiClient={apiClient}
                                        setArPnList={setArPnList}
                                        setArPnValue={setArPnValue}
                                        setSearchStatus={setSearchStatus}
                                        setSelectedMfgPn={setSelectedMfgPn}
                                        selectedMfgPn={selectedMfgPn}
                                        arPnValue={arPnValue}
                                        currentArPnList={currentArPnList}
                                        searchStatusValue={searchStatusValue}
                                        showMfgPN={showMfgPN}
                                        handleMfgChange={handleMfgChange}
                                        mfgPnOptions={mfgPnOptions}
                                        mfgPnSearchStatus={mfgPnSearchStatus}
                                    />
                                    {selectedMfgPn.status != 'UNSET' ? (
                                        <SpaceBetween size='xl'>
                                            <ComponentDetailView
                                                status={selectedMfgPn.status}
                                                mfnValue={mfnValue}
                                                asOriginalEntry={asOriginalEntry}
                                                toolTips={toolTips}
                                                alternateComponent={alternateComponent}
                                                requestPage={'modify'}
                                            />
                                            <Textarea
                                                onChange={({ detail }) =>
                                                    setRequestorNotes(detail.value)
                                                }
                                                value={requestorNotes}
                                                placeholder='Enter notes to give additional context for Admins to help approve this request'
                                            />
                                        </SpaceBetween>
                                    ) : (
                                        <></>
                                    )}
                                </SpaceBetween>
                            </Form>
                        </form>
                    </SpaceBetween>
                    {selectedMfgPn.status != 'UNSET' ? (
                        <Box>
                            <SpaceBetween direction='horizontal' size='m'>
                                <Button
                                    onClick={() => {
                                        setDeleteModalVisible(true)
                                    }}
                                    iconName='close'
                                    variant='primary'
                                >
                                    Delete Component
                                </Button>
                            </SpaceBetween>
                        </Box>
                    ) : (
                        <></>
                    )}
                </SpaceBetween>
                <Modal
                    className='awsui-visual-refresh'
                    onDismiss={() => setDeleteModalVisible(false)}
                    visible={deleteModalVisible}
                    closeAriaLabel='Close modal'
                    footer={
                        <Box float='right'>
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button
                                    onClick={() => {
                                        setDeleteModalVisible(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        setDeleteModalVisible(false)
                                        handleDelete()
                                    }}
                                >
                                    Delete
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header='Are you sure you would like to delete this component?'
                >
                    <Alert dismissAriaLabel='Close alert'>
                        A request will be created to delete component with Manufacturing Part Number
                        (MFG PN): {selectedMfgPn.label.toUpperCase()}
                    </Alert>
                </Modal>

                <Modal
                    className='awsui-visual-refresh'
                    onDismiss={() => window.location.reload(false)}
                    visible={deleteStatusVisible}
                    closeAriaLabel='Close modal'
                    footer={
                        <Box float='right'>
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button variant='normal' href='/'>
                                    Home
                                </Button>
                                <Button variant='primary' href='/delete'>
                                    Delete another component
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header='Delete Status'
                >
                    <Alert dismissAriaLabel='Close alert'>
                        {selectedMfgPn.status === 'verified'
                            ? `New request with request ID ${requestId} placed to delete the component`
                            : `Deleted the request ${requestId}`}
                    </Alert>
                </Modal>
            </Box>
        </ContentLayout>
    )
}

export default DeleteComponentPage
