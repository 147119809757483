import React from 'react'
import axios from 'axios'
import { AppContextProps } from './components/reusable/interfaces/Interfaces'
import { UserRoles } from './components/reusable/constants/Identifiers'

export const AppContext = React.createContext<AppContextProps>({
    apiClient: axios.create(),
    userAlias: '',
    userProps: {
        role: UserRoles.VIEWER,
        userAlias: '',
    },
    logo: '',
})
export const useAppContext = () => React.useContext(AppContext)
