import { APIPaths } from '../constants/ApiPaths'

export function getParameterOptions(apiClient, setOutput) {
    apiClient
        .get(APIPaths.ATTRIBUTES_DETAILS_GET_PARAMETERS)
        .then((response) => {
            setOutput(extractParameters(response.data))
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
        })
}

function extractParameters(attributeData) {
    return attributeData
        .filter((attribute) => !attribute.is_attribute_descriptive)
        .map((attribute) => ({
            value: attribute.attribute_name,
        }))
}

function extractUnits(attributeDetails) {
    return attributeDetails.unit.map((unitValue) => ({
        value: unitValue,
    }))
}

export function getParameterUnits(apiClient, parameter, setOutput) {
    const queryPath = APIPaths.ATTRIBUTES_DETAILS_GET_UNITS + parameter
    apiClient
        .get(queryPath)
        .then((response) => {
            if (response.data.length > 0) {
                setOutput(extractUnits(response.data[0]))
            } else {
                setOutput([])
            }
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
        })
}
