import * as React from 'react'
import {
    Box,
    Cards,
    Container,
    ContentLayout,
    Grid,
    Icon,
    IconProps,
    Header,
    Link,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react/polaris'
import HeaderTemplate from '../reusable/HeaderTemplate'
import {
    PageDescriptions,
    SJ_MAIL,
    SJ_REPORT_BUG_URL,
    SJ_REQUEST_FEATURE_URL,
    SJ_SLACK,
    SJ_USER_GUIDE,
    SJ_VIDEOS_PLAYLIST,
    SJ_WIKI,
} from '../reusable/constants/Identifiers'

const HomePage = (props) => {
    return (
        <ContentLayout
            header={<HeaderTemplate header={'Home'} headerDescription={PageDescriptions.HOME} />}
        >
            <Box margin={{ left: 's', right: 's' }}>
                <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
                    <Container>
                        <SpaceBetween size='m'>
                            <Cards
                                ariaLabels={{
                                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                                    selectionGroupLabel: 'Item selection',
                                }}
                                cardDefinition={{
                                    header: (item) => (
                                        <Box>
                                            <SpaceBetween size='xxs' direction='horizontal'>
                                                <Icon
                                                    name={item.icon as IconProps.Name}
                                                    variant='link'
                                                />
                                                <Link href={`/${item.link}`} fontSize='heading-m'>
                                                    {item.name}
                                                </Link>
                                            </SpaceBetween>
                                        </Box>
                                    ),
                                    sections: [
                                        {
                                            id: 'link',
                                            header: 'link',
                                            content: (item) => item.link,
                                        },
                                        {
                                            id: 'description',
                                            header: '',
                                            content: (item) => item.description,
                                        },
                                        {
                                            id: 'icon',
                                            header: '',
                                            content: (item) => item.icon,
                                        },
                                    ],
                                }}
                                cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
                                visibleSections={['description']}
                                items={[
                                    {
                                        name: 'Search',
                                        alt: 'Search for a component',
                                        link: 'search',
                                        icon: 'search',
                                        description: PageDescriptions.SEARCH,
                                    },
                                    {
                                        name: 'Add',
                                        alt: 'Add a component',
                                        link: 'add',
                                        icon: 'add-plus',
                                        description: PageDescriptions.ADD,
                                    },
                                    {
                                        name: 'Modify',
                                        alt: 'Modify a component',
                                        link: 'modify',
                                        icon: 'edit',
                                        description: PageDescriptions.MODIFY,
                                    },
                                    {
                                        name: 'Delete',
                                        alt: 'Delete a component',
                                        link: 'delete',
                                        icon: 'status-stopped',
                                        description: PageDescriptions.DELETE,
                                    },
                                ]}
                                loadingText='Loading menu'
                            />
                        </SpaceBetween>
                    </Container>
                    <SpaceBetween direction='vertical' size='l'>
                        <Container header={<Header variant='h3'>Have feedback/questions?</Header>}>
                            <SpaceBetween direction='vertical' size='xxxs'>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='edit' />
                                    <Link
                                        external
                                        externalIconAriaLabel='Opens in a new tab'
                                        href={SJ_REQUEST_FEATURE_URL}
                                    >
                                        Request a Feature
                                    </Link>
                                </SpaceBetween>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='bug' />
                                    <Link
                                        external
                                        externalIconAriaLabel='Opens in a new tab'
                                        href={SJ_REPORT_BUG_URL}
                                    >
                                        Report a Bug
                                    </Link>
                                </SpaceBetween>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='envelope' />
                                    <TextContent>Email Us -</TextContent>
                                    <Link href={SJ_MAIL}>AR SJ Development Team</Link>
                                </SpaceBetween>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='contact' />
                                    <TextContent>Join our Slack channel -</TextContent>
                                    <Link href={SJ_SLACK}>Scrub Jay Help & Request</Link>
                                </SpaceBetween>
                            </SpaceBetween>
                        </Container>
                        <Container header={<Header variant='h3'>Learn more</Header>}>
                            <SpaceBetween direction='vertical' size='xxxs'>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='menu' />
                                    <Link
                                        external
                                        externalIconAriaLabel='Opens in a new tab'
                                        href={SJ_USER_GUIDE}
                                    >
                                        Step by step User Guide
                                    </Link>
                                </SpaceBetween>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='caret-right-filled' />
                                    <Link
                                        external
                                        externalIconAriaLabel='Opens in a new tab'
                                        href={SJ_VIDEOS_PLAYLIST}
                                    >
                                        Video tutorials
                                    </Link>
                                </SpaceBetween>
                                <SpaceBetween direction='horizontal' size='xxxs'>
                                    <Icon name='status-info' />
                                    <Link
                                        external
                                        externalIconAriaLabel='Opens in a new tab'
                                        href={SJ_WIKI}
                                    >
                                        Scrub Jay wiki
                                    </Link>
                                </SpaceBetween>
                            </SpaceBetween>
                        </Container>
                    </SpaceBetween>
                </Grid>
            </Box>
        </ContentLayout>
    )
}

export default HomePage
