import {
    Autosuggest,
    Box,
    ColumnLayout,
    Container,
    Select,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react'
import FormFieldPrimaryHeader from '../forms/FormFieldPrimaryHeader'
import FormFieldSecondaryLabel from '../forms/FormFieldSecondaryLabel'
import { getArPnByCategory } from '../../CategoryDao'
import React, { useState } from 'react'
import { categoryProps, MfgPnProps } from '../interfaces/Interfaces'
import { LoadingState } from '../constants/Identifiers'

export interface componentSearchProps {
    categoryOptions: categoryProps[]
    selectedCategory: categoryProps
    setSelectedCategory: any
    apiClient: any
    setArPnList: any
    setArPnValue: any
    setSearchStatus: any
    selectedMfgPn: MfgPnProps
    setSelectedMfgPn: any
    arPnValue: string
    currentArPnList: any
    searchStatusValue: boolean
    showMfgPN: boolean
    handleMfgChange: any
    mfgPnOptions: object[]
    mfgPnSearchStatus: LoadingState
}

const ComponentSearchByCategory = (props: componentSearchProps) => {
    const [selectArPn, setSelectArPn] = useState(false)
    const [arPnStatus, setArPnStatus] = useState<
        LoadingState.ERROR | LoadingState.FINISHED | LoadingState.LOADING
    >(LoadingState.LOADING)
    return (
        <Container>
            <SpaceBetween size='l' direction='vertical'>
                <FormFieldPrimaryHeader
                    header={'Select ARPN'}
                    description={'AR PN is Amazon Robotics Part Number'}
                />
                <ColumnLayout columns={3}>
                    <Box>
                        <FormFieldSecondaryLabel
                            mainContent={'Category'}
                            popover={'Category of the component'}
                        />
                        {props.categoryOptions.length === 0 ? (
                            <Select
                                selectedOption={props.selectedCategory}
                                onChange={({ detail }) =>
                                    props.setSelectedCategory(detail.selectedOption)
                                }
                                options={[]}
                                placeholder='Choose an option'
                                selectedAriaLabel='Selected'
                                statusType='loading'
                                loadingText={'loading categories'}
                            />
                        ) : (
                            <Select
                                selectedOption={props.selectedCategory}
                                onChange={({ detail }) => {
                                    props.setSelectedCategory(detail.selectedOption)
                                    getArPnByCategory(
                                        props.apiClient,
                                        props.setArPnList,
                                        detail.selectedOption.value,
                                        setArPnStatus,
                                    )
                                    props.setArPnValue('')
                                    props.setSelectedMfgPn({
                                        label: 'Select a Mfg PN',
                                        value: '',
                                        status: 'UNSET',
                                    })
                                }}
                                options={props.categoryOptions}
                                placeholder='Choose an option'
                                selectedAriaLabel='Selected'
                                empty='No options'
                            />
                        )}
                    </Box>
                    <Box>
                        <FormFieldSecondaryLabel
                            mainContent={'AR PN'}
                            popover={'Amazon Robotics Part Number'}
                        />
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Autosuggest
                                onChange={({ detail }) => {
                                    props.setArPnValue(detail.value)
                                    props.setSearchStatus(detail.value)
                                    props.setSelectedMfgPn({
                                        label: 'Select a Mfg PN',
                                        value: '',
                                        status: 'UNSET',
                                    })
                                    setSelectArPn(false)
                                }}
                                onSelect={() => {
                                    setSelectArPn(true)
                                }}
                                value={props.arPnValue}
                                options={props.currentArPnList}
                                virtualScroll
                                enteredTextLabel={(value) => `Use: "${value}"`}
                                ariaLabel="Autosuggest ARPN's"
                                loadingText="Loading ARPN's"
                                errorText='Error retrieving ARPN values'
                                statusType={arPnStatus}
                                placeholder={'Enter value'}
                                empty='No matches found'
                            />
                            {props.searchStatusValue && selectArPn ? (
                                <StatusIndicator type='error'>
                                    Cannot find the AR PN, please enter a valid AR PN from the list
                                </StatusIndicator>
                            ) : (
                                <></>
                            )}
                        </SpaceBetween>
                    </Box>
                </ColumnLayout>
                {props.showMfgPN && props.arPnValue != '' ? (
                    <ColumnLayout columns={3}>
                        <Box>
                            <FormFieldSecondaryLabel
                                mainContent={'Mfg PN'}
                                popover={'MFG PN of the component'}
                            />
                            <Select
                                selectedOption={props.selectedMfgPn}
                                onChange={(event) => props.handleMfgChange(event)}
                                options={props.mfgPnOptions}
                                placeholder='Choose an Mfg Pn'
                                errorText={'Failed to fetch Mfg PNs'}
                                selectedAriaLabel='Selected'
                                empty='No options'
                                loadingText={'loading MFG PNs'}
                                statusType={props.mfgPnSearchStatus}
                            />
                        </Box>
                    </ColumnLayout>
                ) : (
                    <></>
                )}
            </SpaceBetween>
        </Container>
    )
}

export default ComponentSearchByCategory
