import { ExpandableSection, HelpPanel } from '@amzn/awsui-components-react/polaris'
import { Icon } from '@amzn/awsui-components-react/polaris'
import {
    SJ_DELIVERY_PLAN,
    SJ_MAIL,
    SJ_USER_GUIDE,
    SJ_VIDEOS_PLAYLIST,
} from '../reusable/constants/Identifiers'

const HelpPanelContainer = (props) => {
    return (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more <Icon name='external' />
                    </h3>
                    <p>
                        For more details on features, how-tos and FAQs on each page, please refer to
                        the following sources
                    </p>
                    <ul>
                        <li>
                            <a href={SJ_USER_GUIDE}>Link to User Guide</a>
                        </li>
                        <li>
                            <a href={SJ_VIDEOS_PLAYLIST}>Link to video tutorials</a>
                        </li>
                    </ul>
                </div>
            }
            header={<h2>Help panel</h2>}
        >
            <div>
                <p>Please refer to this section for a summary of Scrub Jay’s core capabilities</p>

                <ExpandableSection header={'Search a component'}>
                    <p>
                        Scrub Jay’s search functionality empowers users to search the vast
                        parametric data repository of alternate component sourcing.
                    </p>
                    <ul>
                        <li>
                            You can search with an Amazon Robotics Part Number or a Manufacturing
                            Part Number
                        </li>
                        <li>Currently only one value can be searched at a time</li>
                    </ul>
                    <b>Features</b>
                    <ul>
                        <li>
                            At a glance identify difference between Preferred and alternate
                            component
                        </li>
                        <li>Filter through output table</li>
                        <li>Export output to CSV</li>
                        <li>Shareable component details page</li>
                    </ul>
                </ExpandableSection>
            </div>
            <ExpandableSection header={'Add a component'}>
                <p>
                    Create a request to add a new component to Scrub Jay. Once the request is
                    approved by admins, the component will be available as alternate.
                </p>
                <b>Features</b>
                <ul>
                    <li>Get a recommended list of attributes to be added per category</li>
                    <li>Add a custom parameter to your request</li>
                </ul>
            </ExpandableSection>
            <ExpandableSection header={'Modify a component or a pending request'}>
                <p>
                    Create a request to modify any existing components in Scrub Jay. You can also
                    modify any pending request you have created using the Modify page.
                </p>
            </ExpandableSection>
            <ExpandableSection header={'Delete a component or a pending request'}>
                <p>
                    With Delete feature, you can create a request to delete one of the components in
                    Scrub Jay. You can also delete any request you created.
                </p>
            </ExpandableSection>

            <h5>Terminology</h5>
            <dl>
                <dt>AR PN</dt>
                <dd>Amazon Robotics Part Number eg. 120-0040</dd>
                <dt>Mfg PN</dt>
                <dd>
                    Manufacturer Part number; a unique identifier for a Manufacturer’s part eg.
                    MCR10EZHF9761*
                </dd>
                <dt>Is Preferred Alternative</dt>
                <dd>
                    Refers to a component/Mfg PN being the preferred alternative for a AR PN. In the
                    application, hen "Is Preferred Alternative" is Yes, the value of "Is Acceptable
                    Alternate" is blank.
                </dd>
                <dt>Is Acceptable Alternative</dt>
                <dd>
                    Refers to a component being an acceptable alternate component. It takes the
                    values
                    <ol>
                        <li>Yes, can be acceptable as an alternate</li>
                        <li>No, cannot be accepted as an alternative</li>
                        <li>No, but can be used as a deviation</li>
                        <li>In Process or qualification phase</li>
                    </ol>
                </dd>
            </dl>
        </HelpPanel>
    )
}

export default HelpPanelContainer
