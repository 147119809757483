import { AdditionalAttributeDetails } from '../reusable/interfaces/Interfaces'
import { ColumnLayout, Container, SpaceBetween, TextContent } from '@amzn/awsui-components-react'
import React from 'react'

const AdminApprovalPageAttributeDetails = (props) => {
    const { request } = props

    if (Object.keys(request).length === 0) {
        return <></>
    } else {
        const attributes: AdditionalAttributeDetails[] = request['attributes']
        return (
            <ColumnLayout columns={3} variant='text-grid'>
                {attributes.map((attribute) => (
                    <Container>
                        <SpaceBetween size='l'>
                            <SpaceBetween direction='horizontal' size='l'>
                                <TextContent>
                                    <strong>Attribute Name: </strong>
                                    {attribute['label']}
                                </TextContent>
                            </SpaceBetween>
                            <SpaceBetween direction='horizontal' size='l'>
                                <TextContent>
                                    <strong>Value: </strong>
                                    {attribute['value']}
                                </TextContent>
                            </SpaceBetween>
                            <SpaceBetween direction='horizontal' size='l'>
                                <TextContent>
                                    <strong>Unit: </strong>
                                    {attribute['unit']}
                                </TextContent>
                            </SpaceBetween>
                            <SpaceBetween direction='horizontal' size='l'>
                                <TextContent>
                                    <strong>Description: </strong>
                                    {attribute['description']}
                                </TextContent>
                            </SpaceBetween>
                        </SpaceBetween>
                    </Container>
                ))}
            </ColumnLayout>
        )
    }
}

export default AdminApprovalPageAttributeDetails
