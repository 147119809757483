export const InputType = {
    DECIMAL: 'decimal',
    STRING: 'string',
}

export const STRING = 'string'
export const NUMBER = 'number'
export const TEXT = 'text'

export enum UserRoles {
    VIEWER,
    ADMIN,
}
export enum RequestType {
    ADD = 'ADD',
    MODIFY = 'MODIFY',
    DELETE = 'DELETE',
}

export enum RequestStatus {
    NEW = 'new',
    APPROVE = 'approve',
    DENY = 'deny',
}

export enum LoadingState {
    LOADING = 'loading',
    FINISHED = 'finished',
    ERROR = 'error',
}

export enum AcceptableAltValues {
    YES = 'Yes, can be accepted as an alternative',
    NO = 'No, cannot be used as an alternative',
    DEVIATION = 'No, but can be used as a deviation',
    IN_PROCESS = 'In Process or qualification phase',
}

// Get value from string
export namespace AcceptableAltValues {
    export function valueOf(str: string) {
        return AcceptableAltValues[str as keyof typeof AcceptableAltValues]
    }
}

export const SJ_REPORT_BUG_URL =
    'https://t.corp.amazon.com/create/templates/5e2cae94-34a8-416c-8226-27b4bb2ec13f'
export const SJ_REQUEST_FEATURE_URL =
    'https://issues.amazon.com/issues/create?template=f9785dcc-7896-4811-9c7b-00af4e617d20'

export const SJ_USER_GUIDE =
    'https://w.amazon.com/bin/view/Amazon_Robotics/RDST/SSDS/ROSS/Products/ScrubJay/UserGuide'

export const SJ_WIKI =
    'https://w.amazon.com/bin/view/Amazon_Robotics/RDST/SSDS/ROSS/Products/ScrubJay'

export const SJ_SLACK = 'https://amzn-operations.slack.com/archives/C04B7DSM9NE'
export const SJ_MAIL = 'mailto:ar-ssds-ross-devs+scrub_jay@amazon.com'
export const SJ_VIDEOS_PLAYLIST = 'https://broadcast.amazon.com/channels/22480/playlists/31977'
export const SJ_DELIVERY_PLAN =
    'https://quip-amazon.com/K26ZA6qlaQ19/Delivery-plan-for-Scrub-Jay-AME-Alternate-Electronics-Components'

export const PHONETOOL_USER_PREFIX_URL = 'https://phonetool.amazon.com/users/'

export enum AlertTypes {
    WARNING = 'warning',
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
}

export const INVALID_PARAMETERS_MESSAGE =
    'Please input valid values for the above highlighted parameters'

export enum PageDescriptions {
    HOME = 'This tool helps search, update and maintain a repository of components and their alternatives used in Amazon Robotics',
    ADMIN = 'While taking a decision on a request, make sure to record your notes for the decision',
    ADD = 'Add a new Manufacturing Part number against existing AR Part number. A request is created and is merged in main repository upon admin approval',
    SEARCH = 'Search for a component using AR Part number or Manufacturing Part number and get a exportable result',
    DELETE = 'Delete any existing components, ' +
        'either from repository or those yet to be approved. A request is created and is merged in main repository upon admin approval',
    MODIFY = 'Modify any existing components, either from repository or those yet to be approved. A request is created and is merged in main repository upon admin approval',
    SEARCH_DETAIL = "This page contains details of one particular component. You can share this page's URL with others.",
}

export enum SearchPageSummaryPointsTooltip {
    NUM_OF_PREFERRED_COMPONENT = 'A Preferred component is a component where isPreferredAlternative is `True`. isPreferredAlternative is also referred as isOriginal',
    PREFERRED_COMPONENT = 'Copies link to the details page of the preferred component. This link only shows up when there is only one preferred component listed',
    NUM_OF_PARAMETRIC_ATTRIBUTES = 'A Parametric attribute is an attribute associated with a component like Height, Length, Width etc.',
}

export enum BadgeColor {
    GREEN = 'green',
    BLUE = 'blue',
    RED = 'red',
}

export enum LabelsForComponentIDDropdownCategories {
    VERIFIED = 'Verified and Approved',
    IN_REVIEW = 'In-Review and not yet Approved',
}
