import { Box, BreadcrumbGroup, Header } from '@amzn/awsui-components-react/polaris'
import React from 'react'

const HeaderTemplate = (props) => {
    const items = props.items
    const header = props.header
    const headerDescription = props.headerDescription
    const actions = props.actions

    return (
        <Box margin={{ top: 's', left: 's', right: 's' }}>
            <BreadcrumbGroup items={items} ariaLabel='Breadcrumbs' />
            <Header variant='h1' description={headerDescription} actions={actions}>
                {header}
            </Header>
        </Box>
    )
}

export default HeaderTemplate
