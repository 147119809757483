import {
    ColumnLayout,
    Container,
    Form,
    FormField,
    Input,
    SpaceBetween,
    Box,
    Flashbar,
    ContentLayout,
} from '@amzn/awsui-components-react/polaris'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../context'
import SearchOutputTable from './SearchOutputTable'
import Button from '@amzn/awsui-components-react/polaris/button'
import useStore from '../Store'
import './SearchPage.scss'
import { getSearchQueryOutput } from '../reusable/database/ComponentDetailsTableQueries'
import { getCategoryDetails } from '../reusable/database/CategoryDetailsTableQueries'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { PageDescriptions } from '../reusable/constants/Identifiers'

export interface TableDataProps {
    column_name: string
    column_value: string
}

const SearchPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [searchValue, setSearchValue] = useState('')
    const [outputVisible, setOutputVisible] = useState(false)
    const setCategoryDetails = useStore((state) => state.setCategoryDetails)
    const [emptyResponse, setEmptyResponse] = useState(false)
    const [flashBarItems, setFlashBarItems] = React.useState([])
    const [searchResultArray, setSearchResultArray] = useState([])

    useEffect(() => {
        getCategoryDetails(apiClient, setCategoryDetails)
    }, [])

    const handleSearchSubmitClick = (searchValue: string) => {
        setFlashBarItems([])
        setSearchResultArray([])
        setOutputVisible(false)
        setEmptyResponse(false)
        getSearchQueryOutput(
            apiClient,
            searchValue,
            setSearchResultArray,
            setFlashBarItems,
            setEmptyResponse,
            setOutputVisible,
        )
    }

    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Search', href: '' },
                    ]}
                    header={'Search'}
                    headerDescription={PageDescriptions.SEARCH}
                />
            }
        >
            <Box>
                <SpaceBetween size='xl'>
                    <SpaceBetween size='m'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            <Form>
                                <Container>
                                    <FormField
                                        label='Enter AR PN / Mfg PN'
                                        description='AR PN is Amazon Robotics Part Number, Mfg PN is Manufacturing Part Number'
                                        constraintText='Use only one AR PN/Mfg PN value; Search does not support multiple values'
                                        stretch={true}
                                    >
                                        <ColumnLayout columns={2}>
                                            <Input
                                                onChange={(event) =>
                                                    setSearchValue(event.detail.value)
                                                }
                                                value={searchValue}
                                                placeholder='For ex. 120-00464'
                                            />
                                            <Button
                                                variant='primary'
                                                onClick={() => handleSearchSubmitClick(searchValue)}
                                            >
                                                Search
                                            </Button>
                                        </ColumnLayout>
                                    </FormField>
                                </Container>
                            </Form>
                        </form>
                    </SpaceBetween>
                    {outputVisible && emptyResponse ? <Flashbar items={flashBarItems} /> : <></>}
                    {outputVisible && !emptyResponse ? (
                        <SearchOutputTable
                            searchValue={searchValue}
                            searchResultArray={...searchResultArray}
                        />
                    ) : (
                        <></>
                    )}
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default SearchPage
