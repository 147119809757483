// Promise polyfill
import 'core-js/features/promise'

import { render } from 'react-dom'
import App from './components/App'
import '@amzn/awsui-global-styles/polaris.css'
import { getMidwayJwtToken } from './auth/MidwayJwtToken'
import axios from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { StatusCodes } from 'http-status-codes'
import { AppContext } from './context'
import { UserRoles } from './components/reusable/constants/Identifiers'
import { UserProps } from './components/reusable/interfaces/Interfaces'
import { APIPaths } from './components/reusable/constants/ApiPaths'
const cathodeVersions = require('@amzn/cathode-versions-javascript')

const BASE_API_URL = {
    prod: 'https://prod.api.scrubjay.birds.amazon.dev',
    beta: 'https://beta.api.scrubjay.birds.amazon.dev',
    local: 'https://beta.api.scrubjay.birds.amazon.dev',
}

;(async () => {
    let userAlias: string | undefined = ''
    if (process.env.NODE_ENV === 'production') {
        const token = await getMidwayJwtToken()
        const decoded = jwtDecode<JwtPayload>(token)
        userAlias = decoded.sub ? decoded.sub : 'UnidentifiedUser'
    } else {
        // Web application running on local cannot get midway token.
        // In local running application, set user alias manually for testing
        userAlias = 'testUser'
    }

    const appSettings = (await axios('/settings.json')).data
    const stage = appSettings['stage']
    const apiUrl = BASE_API_URL[stage]

    const apiClient = axios.create({
        baseURL: apiUrl,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        responseType: 'json',
        timeout: 15_000,
    })

    // Add Authorization header to all axios calls
    apiClient.interceptors.request.use(async (config) => {
        if (process.env.NODE_ENV === 'production') {
            const jwtToken = await getMidwayJwtToken()
            config.headers.Authorization = `Bearer ${jwtToken}`
        }
        return config
    })

    apiClient.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            const res = error.response
            if (res.status === StatusCodes.UNAUTHORIZED) {
                error.response.message = 'You are not authorized to perform the operation'
            }
            return Promise.reject(error)
        },
    )

    const getUserRole = async () => {
        const isAdmin = (await apiClient.get('/user/is-admin')).data['isAdmin']
        if (isAdmin) {
            return UserRoles.ADMIN
        }
        return UserRoles.VIEWER
    }

    const getTopNavBarLogo = async () => {
        return await apiClient
            .get(APIPaths.SJ_SETTINGS_GET_TOPNAV_LOGO)
            .then((response) => {
                return response.data[0]['value']
            })
            .catch((error) => {
                console.error(error)
                return ''
            })
    }

    const logo: string = await getTopNavBarLogo()

    let userProps: UserProps = {
        userAlias,
        role: UserRoles.VIEWER,
    }

    if (process.env.NODE_ENV === 'production') {
        userProps.role = await getUserRole()
    } else {
        userProps.role = UserRoles.ADMIN
    }

    //User analytics integration(Spectrometer)
    const cathodeConfig = {
        siteName: 'ScrubJay',
        appName: 'ScrubJayMetadataCollectorFrontendWebsite',
    }

    if (userAlias) {
        // Using logged in user as an unique id as cathode-customerId
        document.dispatchEvent(
            new CustomEvent('cathode-customerId', {
                detail: {
                    customerId: userAlias,
                },
            }),
        )
    }

    const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, stage === 'prod')

    const rawBoomerangScript = scriptTags.boomerangLoaderScript

    const boomerangScript = rawBoomerangScript.substring(8, rawBoomerangScript.length - 9)

    const rawListenerScript = scriptTags.listenerScripts

    const listenerScript = rawListenerScript.substring(8, rawListenerScript.length - 9)

    const rawCathodeScript = scriptTags.cathodeScript

    const cathodeScript = rawCathodeScript.substring(8, rawCathodeScript.length - 9)

    render(
        <AppContext.Provider value={{ apiClient, userAlias, userProps, logo }}>
            <div>
                <App />
            </div>
            ,
        </AppContext.Provider>,
        document.querySelector('#app'),
    )

    window.eval(boomerangScript)
    window.eval(listenerScript)
    window.eval(cathodeScript)
})()
