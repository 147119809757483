import { useEffect } from 'react'
import Alert from '@amzn/awsui-components-react/polaris/alert'

import './RouteWithPermission.scss'
import { userHasRole } from '../hooks/userHasRole'

const RouteWithPermission = ({ component: Component, claimWithRole, ...props }) => {
    const shouldCheckForRole = Array.isArray(claimWithRole) && claimWithRole.length
    const canClaimWithRole = userHasRole(claimWithRole)
    const canClaim = !shouldCheckForRole || canClaimWithRole

    useEffect(() => {}, [])

    return canClaim ? (
        <Component {...props} />
    ) : (
        <div className='alert-access-denied'>
            <Alert type='error' header='Access Denied'>
                You do not have permission to access this page.
            </Alert>
        </div>
    )
}
export default RouteWithPermission
