import TopNavbar from './layout/TopNavBar'
import SideNavBar from './layout/SideNavBar'
import { useState } from 'react'
import { AppLayout } from '@amzn/awsui-components-react'
import { Routing } from './Routings'
import './layout/TopNavBar.scss'
import { applyDensity, Density } from '@amzn/awsui-global-styles'
import HelpPanelContainer from './help/HelpPanelContainer'
import UnifiedDataTableLicense from '@amzn/unified-data-table-license'
import UdcLicenseManager from '@amzn/unified-data-table-components/core/licenseManager'

function App() {
    const [navigationOpen, setNavigationOpen] = useState(false)
    const [toolsOpen, setToolsOpen] = useState(false)
    const keyString = UnifiedDataTableLicense.getLicenseKey()
    UdcLicenseManager.setLicenseKey(keyString)

    document.body.classList.add('awsui-visual-refresh')
    applyDensity(Density.Comfortable)
    return (
        <div className='awsui-visual-refresh'>
            <TopNavbar />
            <AppLayout
                navigation={<SideNavBar />}
                navigationOpen={navigationOpen}
                onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
                content={<Routing />}
                headerSelector='#topNav'
                tools={<HelpPanelContainer />}
                toolsHide={false}
                toolsOpen={toolsOpen}
                onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            />
        </div>
    )
}

export default App
