import { Box, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react'
import React from 'react'

export function getAttributeValue(value) {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No'
    }
    return value || '-'
}

const ValueWithLabel = (props) => {
    return (
        <Box>
            <Box variant='awsui-key-label'>
                <SpaceBetween direction='horizontal' size={'xs'}>
                    <div>{props.label}</div>
                    <Popover
                        dismissButton={false}
                        position='top'
                        size='small'
                        triggerType='custom'
                        content={'Here we would have content that describes a particular attribute'}
                    >
                        <StatusIndicator type='info'></StatusIndicator>
                    </Popover>
                </SpaceBetween>
            </Box>
            <div>{props.value}</div>
        </Box>
    )
}

export default ValueWithLabel
