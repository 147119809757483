import { APIPaths } from '../constants/ApiPaths'

export const getCategoryDetails = (apiClient, setOutput) => {
    apiClient
        .get(APIPaths.CATEGORY_DETAILS_GET_ALL)
        .then((response) => {
            setOutput(response.data)
        })
        .catch((error) => {
            console.error(`Error: ${error}`)
        })
}
