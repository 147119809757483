import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    ContentLayout,
    Form,
    Modal,
    SpaceBetween,
    StatusIndicator,
    Textarea,
} from '@amzn/awsui-components-react/polaris'
import { useAppContext } from '../../context'
import { getCategories } from '../CategoryDao'
import {
    getDetailsInRequest,
    getDetailsInVerified,
    getMfgPnVerifiedByUser,
} from '../reusable/database/ComponentDetailsTableQueries'
import ComponentDetailEdit from '../reusable/componentDetail/ComponentDetailSection'
import ComponentSearchByCategory from '../reusable/componentLookup/ComponentSearchByCategory'
import { alternateComponentProps, toolTipsProps } from '../add/AddComponentPage'
import { generateRequestFormat, generateUniqueUuId } from '../reusable/RequestFormatGenerator'
import { categoryProps, MfgPnProps } from '../reusable/interfaces/Interfaces'
import {
    INVALID_PARAMETERS_MESSAGE,
    LoadingState,
    PageDescriptions,
    RequestStatus,
    RequestType,
} from '../reusable/constants/Identifiers'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { verifyInvalid } from '../reusable/functions/verifyAttributes'
import { Flashbar } from '@amzn/awsui-components-react'
import { cleanupToolTips } from '../reusable/functions/OperationsOnAttributes'

const addRequestPostQuery = '/request/orgId/ar/addComponent'

const ModifyComponentPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [selectedCategory, setSelectedCategory] = React.useState<categoryProps>({
        label: 'Select a category',
        value: '',
    })
    const [categoryOptions, setCategoryOptions] = useState<categoryProps[]>([])
    const [currentArPnList, setArPnList] = useState<object[]>([])
    const [arPnValue, setArPnValue] = useState('')
    const [searchStatusValue, setSearchStatusValue] = useState(true)
    const [showMfgPN, setShowMfgPn] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [reviewUuid, setReviewUuid] = useState('')
    const [requestorNotes, setRequestorNotes] = React.useState('')
    const [mfgPnSearchStatus, setMfgPnSearchStatus] = React.useState<
        LoadingState.ERROR | LoadingState.FINISHED | LoadingState.LOADING
    >(LoadingState.LOADING)
    const [selectedMfgPn, setSelectedMfgPn] = React.useState<MfgPnProps>({
        label: 'Select a Mfg PN',
        value: '',
        status: 'UNSET',
    })
    const [mfgPnOptions, setMfgPnOptions] = useState<object[]>([])
    const [showInvalidParamFlashBar, setShowInvalidFlashBar] = React.useState(false)

    useEffect(() => {
        getCategories(apiClient, setCategoryOptions)
    }, [])

    // Details component variables
    const [mfnValue, setMfnValue] = React.useState('')
    const [asOriginalEntry, setAsOriginalEntry] = React.useState(false)
    const [alternateComponent, setAlternateComponent] = React.useState<alternateComponentProps>({
        label: '',
        value: '',
    })
    const [toolTips, setToolTips] = React.useState<toolTipsProps[]>([])
    const [requestModalVisible, setRequestModalVisible] = React.useState(false)

    const setSearchStatus = (values) => {
        if (!values) {
            setSearchStatusValue(true)
        } else {
            for (let i = 0; i < currentArPnList.length; i++) {
                const catValues = Array(Object.values(currentArPnList[i]))
                if (catValues[0]) {
                    if (catValues[0][0] === values) {
                        setSearchStatusValue(false)
                        setShowMfgPn(true)
                        getMfgPnVerifiedByUser(
                            apiClient,
                            mfgPnOptions,
                            setMfgPnOptions,
                            values,
                            appContext.userAlias,
                            setMfgPnSearchStatus,
                        )
                        break
                    }
                    setShowMfgPn(false)
                    setSearchStatusValue(true)
                }
            }
        }
    }

    const handleSubmit = (type) => {
        if (verifyInvalid(toolTips, alternateComponent.value, asOriginalEntry)) {
            setShowInvalidFlashBar(true)
            return
        }
        let uniqueId = reviewUuid
        let payloadRequestId = requestId
        const cleanedUpToolTip = cleanupToolTips(toolTips)
        if (selectedMfgPn.status !== 'review') {
            uniqueId = generateUniqueUuId()
            payloadRequestId = generateRequestFormat(uniqueId, selectedMfgPn.label.toUpperCase())
        }
        const payload = {
            uuid: uniqueId,
            request_id: payloadRequestId,
            category: selectedCategory.label,
            category_id: selectedCategory.value,
            category_id_part_id: arPnValue,
            component_id: selectedMfgPn.label,
            manufacturer_name: mfnValue,
            isPreferredAlternative: asOriginalEntry,
            acceptableAlternative: alternateComponent.value,
            attributes: cleanedUpToolTip,
            type: type,
            status: RequestStatus.NEW,
            requestor_notes: requestorNotes,
            requested_by: appContext.userAlias,
            request_date: Date().toString(),
        }
        try {
            const payloadAsJson = JSON.stringify(payload)
            requestAddComponent(payloadAsJson)
                .then(() => {
                    setRequestId(payloadRequestId)
                    setRequestModalVisible(true)
                })
                .catch((err) => {
                    console.error('error ' + err)
                })
        } catch (err) {
            console.error('error :' + err)
        }
    }

    const requestAddComponent = (payload) => {
        return apiClient.post(addRequestPostQuery, payload)
    }

    const handleMfgChange = (event) => {
        setSelectedMfgPn(event.detail.selectedOption)
        if (event.detail.selectedOption.status === 'review') {
            getDetailsInRequest(
                apiClient,
                setMfnValue,
                setAsOriginalEntry,
                setAlternateComponent,
                setToolTips,
                arPnValue,
                setReviewUuid,
                setRequestId,
                event.detail.selectedOption.label,
            )
        } else {
            getDetailsInVerified(
                apiClient,
                setMfnValue,
                setAsOriginalEntry,
                setAlternateComponent,
                setToolTips,
                arPnValue,
                event.detail.selectedOption.label,
            )
        }
    }

    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Modify', href: '' },
                    ]}
                    header={'Modify a component'}
                    headerDescription={PageDescriptions.MODIFY}
                />
            }
        >
            <Box>
                <SpaceBetween size='xl'>
                    <SpaceBetween size='m'>
                        <form onSubmit={(event) => event.preventDefault()}>
                            <Form>
                                <SpaceBetween direction='vertical' size='l'>
                                    <ComponentSearchByCategory
                                        categoryOptions={categoryOptions}
                                        selectedCategory={selectedCategory}
                                        setSelectedCategory={setSelectedCategory}
                                        apiClient={apiClient}
                                        setArPnList={setArPnList}
                                        setArPnValue={setArPnValue}
                                        setSearchStatus={setSearchStatus}
                                        selectedMfgPn={selectedMfgPn}
                                        setSelectedMfgPn={setSelectedMfgPn}
                                        arPnValue={arPnValue}
                                        currentArPnList={currentArPnList}
                                        searchStatusValue={searchStatusValue}
                                        showMfgPN={showMfgPN}
                                        handleMfgChange={handleMfgChange}
                                        mfgPnOptions={mfgPnOptions}
                                        mfgPnSearchStatus={mfgPnSearchStatus}
                                    />
                                    {selectedMfgPn.status != 'UNSET' ? (
                                        <SpaceBetween size='xl'>
                                            <ComponentDetailEdit
                                                status={selectedMfgPn.status}
                                                mfnValue={mfnValue}
                                                setMfnValue={setMfnValue}
                                                asOriginalEntry={asOriginalEntry}
                                                setAsOriginalEntry={setAsOriginalEntry}
                                                toolTips={toolTips}
                                                setToolTips={setToolTips}
                                                alternateComponent={alternateComponent}
                                                setAlternateComponent={setAlternateComponent}
                                                requestPage={'modify'}
                                            />
                                            <Textarea
                                                onChange={({ detail }) =>
                                                    setRequestorNotes(detail.value)
                                                }
                                                value={requestorNotes}
                                                placeholder='Enter notes to give additional context for Admins to help approve this request'
                                            />
                                            {showInvalidParamFlashBar ? (
                                                <Flashbar
                                                    items={[
                                                        {
                                                            header: INVALID_PARAMETERS_MESSAGE,
                                                            type: 'error',
                                                            content:
                                                                'This is a dismissible error message.',
                                                            dismissible: true,
                                                            dismissLabel: 'Dismiss message',
                                                            onDismiss: () =>
                                                                setShowInvalidFlashBar(false),
                                                        },
                                                    ]}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </SpaceBetween>
                                    ) : (
                                        <></>
                                    )}
                                </SpaceBetween>
                            </Form>
                        </form>
                    </SpaceBetween>
                    {selectedMfgPn.status != 'UNSET' ? (
                        <Box>
                            <SpaceBetween direction='horizontal' size='m'>
                                <Button
                                    disabled={
                                        true /*Setting this as true as it's not in scope of MLP*/
                                    }
                                >
                                    Save as draft
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleSubmit(RequestType.MODIFY)
                                    }}
                                    variant='primary'
                                >
                                    Submit
                                </Button>
                            </SpaceBetween>
                        </Box>
                    ) : (
                        <></>
                    )}
                </SpaceBetween>
                <Modal
                    className='awsui-visual-refresh'
                    onDismiss={() => window.location.reload(false)}
                    visible={requestModalVisible}
                    closeAriaLabel='Close modal'
                    footer={
                        <Box float='right'>
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button href='/'>Go to home page</Button>
                                <Button variant='primary' href='/modify'>
                                    Modify another component
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header='Requested to add a new parameter'
                >
                    <StatusIndicator type='info'>
                        New request with Request ID: {requestId} created.
                    </StatusIndicator>
                </Modal>
            </Box>
        </ContentLayout>
    )
}

export default ModifyComponentPage
