import { useAppContext } from '../context'

export const userHasRole = (roleNames) => {
    const { userProps } = useAppContext()
    const userRole = userProps.role

    if (Array.isArray(roleNames)) {
        return roleNames.some((role) => role === userRole)
    }

    return roleNames === userRole
}
