import {
    FormField,
    Input,
    SpaceBetween,
    TextContent,
    Popover,
    Icon,
} from '@amzn/awsui-components-react/polaris'
import React, { useEffect } from 'react'
import { InputType, NUMBER, TEXT } from '../constants/Identifiers'

export interface formFieldProps {
    value: string
    header: string
    content: string
    placeholder: string
    setValue: any
    handleInput: any
    constraintType: string
    inputStatus: Function
}

const AddFormField = (props: formFieldProps) => {
    const [value, setValue] = React.useState(props.value)
    const [isInvalid, setIsInvalid] = React.useState(false)
    const [invalidInputMessage, setInvalidInputMessage] = React.useState('')

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {
        props.handleInput(value, props.setValue, isInvalid)
    })

    const checkType = (value, type) => {
        if (isNaN(value) && type === InputType.DECIMAL) {
            setIsInvalid(true)
            setInvalidInputMessage(`Please enter a decimal value`)
        } else {
            setIsInvalid(false)
            setInvalidInputMessage('')
        }
    }

    return (
        <FormField
            errorText={invalidInputMessage}
            label={
                <SpaceBetween direction='horizontal' size='xs'>
                    <TextContent>
                        <strong>
                            {props.placeholder
                                ? `${props.header}(${props.placeholder})`
                                : `${props.header}`}
                        </strong>
                    </TextContent>
                    <Popover
                        position='top'
                        size='small'
                        triggerType='custom'
                        content={props.content}
                    >
                        <Icon name='status-info' />
                    </Popover>
                </SpaceBetween>
            }
        >
            <Input
                invalid={isInvalid}
                onChange={({ detail }) => {
                    checkType(detail.value, props.constraintType)
                    setValue(detail.value)
                    props.handleInput(detail.value, props.setValue, isInvalid)
                }}
                value={value}
            />
        </FormField>
    )
}

export default AddFormField
