export enum APIPaths {
    COMPONENT_DETAILS_ADD_COMPONENT = '/component/details/orgId/ar/addComponent',
    COMPONENT_DETAILS_DELETE_COMPONENT = 'component/details/orgId/ar/deleteComponent',
    COMPONENT_DETAILS_SEARCH_BY_PART_ID = '/component/details/orgId/ar/partId/',
    COMPONENT_DETAILS_SEARCH_BY_COMPONENT_ID = '/component/details/orgId/ar/componentId/',
    CATEGORY_DETAILS_GET_ALL = '/category/details/orgId/ar',
    CATEGORY_DETAILS_GET_ARPN_BY_CATEGORY_ID = '/category/arPn/orgId/ar/categoryId/',
    ADMIN_GET_PENDING_REQUESTS = '/admin/orgId/ar/pendingRequests',
    ADMIN_POST_DECISION = '/admin/orgId/ar/decision',
    REQUEST_DETAILS_SEARCH_BY_PART_ID = '/request/details/orgId/ar/partId/',
    REQUEST_DETAILS_ADD = '/request/orgId/ar/addComponent',
    ATTRIBUTES_DETAILS_GET_PARAMETERS = '/attributes/details/orgId/ar',
    ATTRIBUTES_DETAILS_GET_UNITS = '/attributes/getUnits/orgId/ar/parameter/',
    SJ_SETTINGS_GET_TOPNAV_LOGO = '/settings/topnavbar_logo',
}
