import { Box } from '@amzn/awsui-components-react/polaris'
import { ThemeProvider } from '@amzn/storm-ui'
import React, { useState } from 'react'
import {
    ClientSideExportButton,
    FooterPanelContainer,
    HeaderPanelContainer,
    LinkCellRenderer,
    Pagination,
    PanelGroup,
    QuickFilter,
    TextCellRenderer,
    UnifiedDataTable,
} from '@amzn/unified-data-table-components/core'
import { Header } from '@amzn/awsui-components-react'

interface TropicalUITableProps {
    tableName: string
    exportFileName: string
    data: any[]
    columnDefinitionsGenerator: any
}

const TropicalUITable = (props: TropicalUITableProps) => {
    const rowData = props.data
    const columnDefs = props.columnDefinitionsGenerator(rowData)

    const gridOptions = {
        rowData,
        columnDefs,
        components: {
            TextCellRenderer,
            LinkCellRenderer,
        },
        getRowHeight: () => 60,
        groupHeaderHeight: 25,
        headerHeight: 40,
        paginationPageSize: 50,
    }

    const FooterPanel = () => (
        <FooterPanelContainer>
            <PanelGroup align='end'>
                <Pagination pageSizes={[50, 100]} />
            </PanelGroup>
        </FooterPanelContainer>
    )

    const HeaderPanel = () => (
        <HeaderPanelContainer>
            <PanelGroup align='start'>
                <Header>
                    <span> Output </span>
                </Header>
            </PanelGroup>
            <PanelGroup align='end'>
                <QuickFilter placeholder={'Search table'} />
                <ClientSideExportButton onExport={handleClientSideExport} />
            </PanelGroup>
        </HeaderPanelContainer>
    )

    const [
        gridApi, // used to expose TropicalUI grid's props for export feature.
        setGridApi,
    ] = useState<any>()

    const onGridReady = (params: any): void => {
        setGridApi(params.api)
    }

    const handleClientSideExport = () => {
        const now = new Date()
        gridApi.exportDataAsCsv({
            fileName:
                props.exportFileName +
                '_' +
                now.getMonth() +
                '_' +
                now.getDate() +
                '_' +
                now.getFullYear() +
                '.csv',
        })
    }

    return (
        <Box>
            <ThemeProvider>
                <UnifiedDataTable
                    uidPrefix={props.tableName}
                    locale='en-US'
                    maxTableHeight='800px'
                    gridOptions={gridOptions}
                    headerPanel={<HeaderPanel />}
                    footerPanel={<FooterPanel />}
                    onGridReady={onGridReady}
                />
            </ThemeProvider>
            <div id='portal'></div> {/*} Prerequisites of StormUI */}
        </Box>
    )
}

export default TropicalUITable
