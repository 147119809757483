import {
    Button,
    SpaceBetween,
    TextContent,
    Container,
    FormField,
    Header,
    Input,
    Popover,
    Icon,
    ColumnLayout,
    StatusIndicator,
} from '@amzn/awsui-components-react/polaris'
import React from 'react'
import { verifyMfgPnExists } from '../CategoryDao'
import { useAppContext } from '../../context'

const AddComponentMfg = (props) => {
    const [mfgExists, setMfgExists] = React.useState(false)
    const [mfgVerified, setMfgVerified] = React.useState(false)

    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    return (
        <Container>
            <SpaceBetween direction='vertical' size='l'>
                <FormField
                    label={
                        <TextContent>
                            <Header variant='h3'> Input Mfg PN </Header>
                        </TextContent>
                    }
                    description='Mfg PN is Manufacturing part number of the component'
                    stretch={true}
                ></FormField>
                <SpaceBetween direction='vertical' size='l'>
                    <FormField
                        label={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <TextContent>
                                    <strong>Mfg PN</strong>
                                </TextContent>
                                <Popover
                                    position='top'
                                    size='small'
                                    triggerType='custom'
                                    content='Manufacturing part number'
                                >
                                    <Icon name='status-info' />
                                </Popover>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction='vertical' size='xs'>
                            <ColumnLayout columns={3}>
                                <Input
                                    onChange={({ detail }) => {
                                        props.setMfgValue(detail.value)
                                        props.onAdd(false)
                                        setMfgVerified(false)
                                    }}
                                    value={props.mfgValue}
                                    placeholder='Enter Mfn number'
                                />
                                <Button
                                    disabled={props.mfgValue === ''}
                                    onClick={() => {
                                        verifyMfgPnExists(
                                            apiClient,
                                            setMfgExists,
                                            props.onAdd,
                                            props.arPN,
                                            props.mfgValue,
                                            setMfgVerified,
                                        )
                                    }}
                                >
                                    Verify
                                </Button>
                            </ColumnLayout>
                            {mfgExists ? (
                                <StatusIndicator type='error'>
                                    Mfg PN already exists, please enter an unique value
                                </StatusIndicator>
                            ) : mfgVerified ? (
                                <StatusIndicator type='success'>
                                    This Mfg PN is available for use against selected AR PN
                                </StatusIndicator>
                            ) : (
                                <></>
                            )}
                        </SpaceBetween>
                    </FormField>
                </SpaceBetween>
            </SpaceBetween>
        </Container>
    )
}

export default AddComponentMfg
