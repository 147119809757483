import {
    Box,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris'
import React from 'react'
import ValueWithLabel from '../forms/ValueLabel'
import { Alert } from '@amzn/awsui-components-react'
import { LabelsForComponentIDDropdownCategories } from '../constants/Identifiers'

const ComponentDetailView = (props) => {
    return (
        <Container>
            <SpaceBetween direction='vertical' size='s'>
                {props.requestPage === 'modify' ? (
                    props.status === 'review' ? (
                        <Alert
                            header={`Part Status: ${LabelsForComponentIDDropdownCategories.IN_REVIEW}`}
                        ></Alert>
                    ) : (
                        <Alert
                            header={`Part Status: ${LabelsForComponentIDDropdownCategories.VERIFIED}`}
                        ></Alert>
                    )
                ) : (
                    <></>
                )}
                <Header variant='h3'> Details </Header>
                <ColumnLayout columns={3} variant='text-grid'>
                    <ValueWithLabel label={'Manufacturer'} value={props.mfnValue} />
                    <ValueWithLabel
                        label={'Is Preferred Alternative'}
                        value={props.asOriginalEntry.toString()}
                    />
                    <ValueWithLabel
                        label={'Acceptable Alternative'}
                        value={props.alternateComponent.value}
                    />
                    {props.toolTips.map((recommendedAttributes, index) => {
                        return (
                            <Box key={index}>
                                <ValueWithLabel
                                    label={recommendedAttributes.label}
                                    value={recommendedAttributes.value}
                                />
                            </Box>
                        )
                    })}
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    )
}

export default ComponentDetailView
