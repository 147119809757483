import React, { useEffect, useState } from 'react'
import {
    Badge,
    Box,
    Button,
    ColumnLayout,
    Container,
    Flashbar,
    Header,
    Link,
    Modal,
    Popover,
    SpaceBetween,
    Spinner,
    StatusIndicator,
    Textarea,
    TextContent,
    ContentLayout,
} from '@amzn/awsui-components-react/polaris'
import {
    getPendingRequests,
    putDecisionOnRequest,
} from '../reusable/database/RequestDetailsTableQueries'
import { useAppContext } from '../../context'
import { AddComponentPayload, AttributeDetail, RequestDecisionPayload } from '../../openapi_server'
import {
    copyRequestToComponentDetails,
    deleteComponent,
} from '../reusable/database/ComponentDetailsTableQueries'
import {
    AlertTypes,
    PageDescriptions,
    PHONETOOL_USER_PREFIX_URL,
    RequestStatus,
    RequestType,
} from '../reusable/constants/Identifiers'
import AdminApprovalPageAttributeDetails from './AdminApprovalPageAttributeDetails'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { getAttributeValue } from '../reusable/forms/ValueLabel'

const AdminApprovalPage = () => {
    const [pendingRequests, setPendingRequests] = useState([])
    const [pendingRequestsAvailable, setPendingRequestsAvailable] = useState(false)
    const [currentRequestNumber, setCurrentRequestNumber] = useState(0)
    const [currentRequest, setCurrentRequest] = useState({})
    const [errorOnDecisionSubmission, setErrorOnDecisionSubmission] = useState(false)
    const [errorOnCopyingRequestSubmission, setErrorOnCopyingRequestSubmission] = useState(false)
    const [decisionOnRequest, setDecisionOnRequest] = useState(RequestStatus.NEW)
    const [decisionConfirmationModalVisible, setDecisionConfirmationModalVisible] = useState(false)
    const [adminNotes, setAdminNotes] = useState('')
    const [flashBarItems, setFlashBarItems] = useState<object[]>([])
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    let flashBarContent, flashBarType, flashBarHeader

    useEffect(() => {
        getPendingRequests(
            apiClient,
            setCurrentRequest,
            setPendingRequests,
            setPendingRequestsAvailable,
        )
        setCurrentRequestNumber(0)
        setAdminNotes('')
    }, [])

    const manageCurrentRequestNumber = (count: number) => {
        let newCurrentRequestNumber: number = currentRequestNumber + count
        if (newCurrentRequestNumber >= 0 && newCurrentRequestNumber < pendingRequests.length) {
            setCurrentRequestNumber(newCurrentRequestNumber)
            setCurrentRequest(pendingRequests[newCurrentRequestNumber])
            setAdminNotes('')
        }
    }

    const attributesToHide = [
        'uuid',
        'type',
        'status',
        'category_id_part_id',
        'requested_by',
        'category_id',
        'category',
        'mfgPn',
        'request_id',
        'component_id',
        'requestor_notes',
    ]

    const getAttributeRename = (attributeName: string) => {
        const attributeRenames = {
            category_id: 'Category ID',
            mfgPn: 'Mfg PN',
            component_id: 'Mfg PN',
            category_id_part_id: 'AR PN',
            acceptableAlternative: 'Is Acceptable Alternate?',
            isPreferredAlternative: 'Is Preferred Component?',
            request_id: 'Request ID',
            manufacturer_name: 'Manufacturer',
            category: 'Category',
            request_date: 'Request Date',
        }
        return attributeName in attributeRenames ? attributeRenames[attributeName] : attributeName
    }

    const getAttributeList = (attributes): AttributeDetail[] => {
        let attributeList: AttributeDetail[] = []

        for (let attribute of attributes) {
            let newLabel: string = attribute['label']

            if (attribute['unit'] && attribute['unit'] !== '') {
                newLabel += '(' + attribute['unit'] + ')'
            }

            const shortedAttribute: AttributeDetail = {
                attribute_label: newLabel,
                attribute_value: attribute['value'],
            }
            attributeList.push(shortedAttribute)
        }
        return attributeList
    }

    const handleDecisionOnRequest = async (
        request,
        requestNumber: number,
        decision: RequestStatus,
        adminNotes: string,
    ) => {
        const request_id_for_decision = request['request_id']
        const decisionPayload: RequestDecisionPayload = {
            uuid: request.uuid,
            request_id: request.request_id,
            category_id_part_id: request.category_id_part_id,
            admin_notes: adminNotes,
            status: decision,
            decision_made_by: appContext.userAlias,
        }

        const component_detail: AddComponentPayload = {
            category_id: request.category_id,
            category_id_part_id: request.category_id_part_id,
            component_id: request.component_id,
            manufacturer_name: request.manufacturer_name,
            isPreferredAlternative: request.isPreferredAlternative,
            WhereUsedPCBA: '',
            AcceptableAlt: request.acceptableAlternative,
            Attributes: getAttributeList(request.attributes),
        }

        await putDecisionOnRequest(apiClient, decisionPayload, setErrorOnDecisionSubmission)

        if (decision === RequestStatus.APPROVE) {
            if (request.type === RequestType.ADD || request.type === RequestType.MODIFY) {
                await copyRequestToComponentDetails(
                    apiClient,
                    component_detail,
                    setErrorOnCopyingRequestSubmission,
                )
            } else if (request.type === RequestType.DELETE) {
                await deleteComponent(
                    apiClient,
                    request.category_id_part_id,
                    request.component_id,
                    setErrorOnCopyingRequestSubmission,
                )
            }
        }

        setDecisionConfirmationModalVisible(false)
        setDecisionOnRequest(RequestStatus.NEW)

        if (errorOnDecisionSubmission || errorOnCopyingRequestSubmission) {
            flashBarHeader = 'Something went wrong while submitting the decision'
            flashBarContent = `Please let us know about it using Report a bug feature in Top Navigation bar`
            flashBarType = AlertTypes.ERROR
            setErrorOnDecisionSubmission(false)
            setErrorOnCopyingRequestSubmission(false)
        } else {
            setPendingRequests((allRequest) => {
                const newPendingRequest = allRequest.filter(
                    (req) => req['request_id'] !== request_id_for_decision,
                )
                if (requestNumber >= 0 && requestNumber < newPendingRequest.length) {
                    setCurrentRequestNumber(requestNumber)
                    setCurrentRequest(newPendingRequest[requestNumber])
                } else if (requestNumber >= newPendingRequest.length && requestNumber >= 0) {
                    setCurrentRequestNumber(requestNumber - 1)
                    setCurrentRequest(newPendingRequest[requestNumber - 1])
                } else {
                    // NO-OP: It would come to else only if request < 0, which is not possible with current logic.
                    // We would end up showing the "No requests are pending" page
                }
                return newPendingRequest
            })

            flashBarHeader = 'Decision submitted'
            flashBarContent = 'Successfully submitted decision on ' + request_id_for_decision
            flashBarType = AlertTypes.SUCCESS
            setAdminNotes('')
        }

        setFlashBarItems([
            ...flashBarItems,
            {
                header: flashBarHeader,
                type: flashBarType,
                content: flashBarContent,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () =>
                    setFlashBarItems((items) =>
                        items.filter((item) => item['id'] !== request_id_for_decision),
                    ),
                id: request_id_for_decision,
            },
        ])
    }

    const ValueWithLabel = ({ label, children }) => (
        <Box>
            <Box variant='awsui-key-label'>
                <SpaceBetween direction='horizontal' size={'xs'}>
                    {label}
                    <Popover
                        dismissButton={false}
                        position='top'
                        size='small'
                        triggerType='custom'
                        content={'Here we would have content that describes a particular attribute'}
                    >
                        <StatusIndicator type='info'></StatusIndicator>
                    </Popover>
                </SpaceBetween>
            </Box>
            <Box>{children}</Box>
        </Box>
    )

    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Admin', href: '' },
                    ]}
                    header={'Admin Approval'}
                    headerDescription={PageDescriptions.ADMIN}
                    actions={
                        <Box>
                            {pendingRequestsAvailable && pendingRequests.length > 0 ? (
                                <SpaceBetween direction='horizontal' size='xs'>
                                    {currentRequestNumber > 0 ? (
                                        <Button
                                            variant='primary'
                                            iconName='angle-left'
                                            onClick={() => {
                                                manageCurrentRequestNumber(-1)
                                            }}
                                        >
                                            Previous
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                    <Box margin='xxs' padding='xxs'>
                                        {currentRequestNumber + 1} out of {pendingRequests.length}
                                    </Box>
                                    {currentRequestNumber < pendingRequests.length - 1 ? (
                                        <Button
                                            variant='primary'
                                            iconAlign='right'
                                            iconName='angle-right'
                                            onClick={() => {
                                                manageCurrentRequestNumber(1)
                                            }}
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </SpaceBetween>
                            ) : (
                                <></>
                            )}
                        </Box>
                    }
                />
            }
        >
            <Box>
                <SpaceBetween size='xl'>
                    <Flashbar items={flashBarItems} />
                    {pendingRequestsAvailable && pendingRequests.length === 0 ? (
                        <Container>
                            <iframe
                                src='https://giphy.com/embed/xJv4OcoadW3gakS75r'
                                className='giphy-embed'
                                allowFullScreen
                            ></iframe>
                            <TextContent>
                                <h3>Hurry! You have no requests for decision.</h3>
                            </TextContent>
                        </Container>
                    ) : (
                        <></>
                    )}
                    {pendingRequestsAvailable && pendingRequests.length > 0 ? (
                        <Box>
                            <SpaceBetween size='l'>
                                <Container
                                    header={
                                        <Header variant='h2' description=''>
                                            <SpaceBetween direction='horizontal' size='l'>
                                                Request Details
                                            </SpaceBetween>
                                        </Header>
                                    }
                                >
                                    <ColumnLayout columns={3} variant='text-grid'>
                                        <ValueWithLabel label='Request Type'>
                                            <Badge color='blue'>{currentRequest['type']}</Badge>
                                        </ValueWithLabel>
                                        <ValueWithLabel label='Requestor'>
                                            <Link
                                                variant='primary'
                                                external
                                                href={
                                                    PHONETOOL_USER_PREFIX_URL +
                                                    currentRequest['requested_by']
                                                }
                                            >
                                                {currentRequest['requested_by']}
                                            </Link>
                                        </ValueWithLabel>
                                        <ValueWithLabel label='Request ID'>
                                            {currentRequest['request_id']}
                                        </ValueWithLabel>
                                        <ValueWithLabel label='Category ID'>
                                            {currentRequest['category_id']}
                                        </ValueWithLabel>
                                        <ValueWithLabel label='Category'>
                                            {currentRequest['category']}
                                        </ValueWithLabel>
                                        <ValueWithLabel label='ARPN'>
                                            {currentRequest['category_id_part_id']}
                                        </ValueWithLabel>
                                        <ValueWithLabel label='Mfg PN'>
                                            {currentRequest['component_id']}
                                        </ValueWithLabel>
                                        <ValueWithLabel label='Requestor Notes'>
                                            {currentRequest['requestor_notes']}
                                        </ValueWithLabel>
                                        {Object.entries(currentRequest).map(([key, value]) =>
                                            value !== undefined &&
                                            value !== '' &&
                                            key !== 'attributes' &&
                                            !attributesToHide.includes(key) ? (
                                                <SpaceBetween size={'l'}>
                                                    <ValueWithLabel label={getAttributeRename(key)}>
                                                        {getAttributeValue(value)}
                                                    </ValueWithLabel>
                                                </SpaceBetween>
                                            ) : (
                                                <></>
                                            ),
                                        )}
                                    </ColumnLayout>
                                </Container>
                                <Container
                                    header={
                                        <Header variant='h2' description=''>
                                            Request Attributes
                                        </Header>
                                    }
                                >
                                    <AdminApprovalPageAttributeDetails request={currentRequest} />
                                </Container>
                                <Textarea
                                    onChange={({ detail }) => setAdminNotes(detail.value)}
                                    value={adminNotes}
                                    placeholder='Enter notes on decision'
                                />
                                <SpaceBetween direction='horizontal' size='m'>
                                    <Button
                                        onClick={() => {
                                            setDecisionOnRequest(RequestStatus.APPROVE)
                                            setDecisionConfirmationModalVisible(true)
                                        }}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDecisionOnRequest(RequestStatus.DENY)
                                            setDecisionConfirmationModalVisible(true)
                                        }}
                                    >
                                        Deny
                                    </Button>
                                </SpaceBetween>
                            </SpaceBetween>
                        </Box>
                    ) : !pendingRequestsAvailable ? (
                        <Spinner size='large' />
                    ) : (
                        <></>
                    )}
                    <Modal
                        className='awsui-visual-refresh'
                        visible={decisionConfirmationModalVisible}
                        onDismiss={() => setDecisionConfirmationModalVisible(false)}
                        closeAriaLabel='Close modal'
                        header='Confirm decision on request'
                        footer={
                            <Box float='right'>
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button variant='link' href='/admin/orgId/ar/pendingRequests'>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='primary'
                                        onClick={(event) => {
                                            handleDecisionOnRequest(
                                                currentRequest,
                                                currentRequestNumber,
                                                decisionOnRequest,
                                                adminNotes,
                                            )
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                    >
                        You are about to <strong>{decisionOnRequest}</strong> this request. This
                        action cannot be undone.
                    </Modal>
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default AdminApprovalPage
